import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AccountRoutingModule } from './account-routing.module';
import { ProfileComponent } from './profile/profile.component';
import { MaterialModule } from '../material/material.module';
import { NgxSpinnerModule } from 'ngx-spinner';
import { PackageSelectComponent } from './package-select/package-select.component';
import { PaymentService } from '../_core/services/payment.service';
import { DialogOtpComponent } from './dialog-otp/dialog-otp.component';
import { SigninComponent } from './signin/signin.component';
import { LoginPageComponent } from './signin/login-page/login-page.component';
import { NewAccountPageComponent } from './signin/new-account-page/new-account-page.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { PasswordResetPageComponent } from './signin/password-reset-page/password-reset-page.component';
import { RecaptchaModule, RecaptchaSettings, RECAPTCHA_SETTINGS } from 'ng-recaptcha';
import { environment } from 'src/environments/environment';
import { OtpComponent } from './otp/otp.component';
import { SharedModule } from '../shared/shared.module';


@NgModule({
  declarations: [
    ProfileComponent, 
    PackageSelectComponent, 
    DialogOtpComponent,
    SigninComponent,
    LoginPageComponent,
    NewAccountPageComponent,
    PasswordResetPageComponent,
    OtpComponent,
  ],
  imports: [
    CommonModule,
    AccountRoutingModule,
    MaterialModule,
    NgxSpinnerModule,
    NgSelectModule,
    RecaptchaModule,
    SharedModule
  ],
  entryComponents: [ProfileComponent, DialogOtpComponent],
  providers: [
    PaymentService,
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: {
        siteKey: environment.recaptcha.siteKey,
      } as RecaptchaSettings,
    },
  ]
})
export class AccountModule { }
