import { Component, Inject, NgZone, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-alert-message',
  templateUrl: './alert-message.component.html',
  styleUrls: ['./alert-message.component.scss']
})
export class AlertMessageComponent implements OnInit {

  message: string;
  alertType: string;
  header: string = "Try again!!";

  constructor(
    private dialogRef: MatDialogRef<AlertMessageComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private ngZone: NgZone
  ) {
    this.message = data.message ? data.message : data.message.message;
    this.alertType = data.type;
    this.header = (!!data.header) ? data.header : this.header;
  }

  ngOnInit() {

  }

  continue() {
    this.ngZone.run(() => {
      this.dialogRef.close();
    })
  }
}
