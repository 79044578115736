interface Scripts {
    name: string;
    src: string;
}

export const ScriptStore: Scripts[] = [
    {
        name: 'plyr',
        src: 'https://cdn.plyr.io/3.5.3/plyr.polyfilled.js'
    },
    {
        name: 'shaka-player',
        src: 'https://cdnjs.cloudflare.com/ajax/libs/shaka-player/3.0.3/shaka-player.compiled.js'
    },
    {
        name: 'shaka-ui',
        src: 'https://cdnjs.cloudflare.com/ajax/libs/shaka-player/3.0.3/shaka-player.ui.js'
    },
    {
        name: 'mux',
        src: 'https://cdnjs.cloudflare.com/ajax/libs/mux.js/5.10.0/mux.min.js'
    },
    {
        name: 'plyr-shaka',
        src: 'https://cdn.jsdelivr.net/npm/@baladshow/plyr-shaka@4.5.9/dist/plyr.min.js'
    },
    {
        name: 'ims',
        src: 'https://imasdk.googleapis.com/js/sdkloader/ima3.js'
    },

    {
        name: 'stripe',
        src: 'https://js.stripe.com/v3/'
    },
    {
        name: 'draggable',
        src: '../../../assets/js/draggable.js'
    }
];