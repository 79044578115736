import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';

@Component({
  selector: 'app-download-ios',
  templateUrl: './download-ios.component.html',
  styleUrls: ['./download-ios.component.scss']
})
export class DownloadIosComponent implements OnInit {

  constructor(private matDialog: MatDialog) { }

  ngOnInit() {
  }

  closeBanner() {
    this.matDialog.closeAll();
  }

  downloadApp() {
    window.open('https://apps.apple.com/lk/app/dflix/id1560605917', '_blank')
  }

}
