import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AuthenticationService } from 'src/app/_core/services/authentication.service';
import { PaymentService } from 'src/app/_core/services/payment.service';
import { PopupService } from 'src/app/_core/services/popup.service';

@Component({
  selector: 'app-payment-hold-error',
  templateUrl: './payment-hold-error.component.html',
  styleUrls: ['./payment-hold-error.component.scss']
})
export class PaymentHoldErrorComponent implements OnInit {

  @Output() hideEvent = new EventEmitter()
  isShowLoadingIcon: boolean = false;

  constructor(
    private paymentService: PaymentService,
    private authenticationService: AuthenticationService,
    private popupService: PopupService
  ) { }

  ngOnInit() {
  }
  hideWarning() {
    this.hideEvent.emit()
  }
  retryPayment() {
    let currentUser = this.authenticationService.currentUser();
    this.paymentService.retryPayment(currentUser.user_id)
      .subscribe(
        (res) => {
          this.hideEvent.emit();
          this.isShowLoadingIcon = false;
          if (res.payment_status == "ACCEPTED") {
            this.popupService.openAlertMessageComponent("Your subscription renewed", "success", "Subscription succeed")
            let user = this.authenticationService.currentUser();
            user.grace_period_on = false;
            this.authenticationService.setTokenCookie(user)
          } else {
            this.popupService.openAlertMessageComponent("Something went wrong", "warning", "Subscription Failed")
          }
        }
      );
  }
}
