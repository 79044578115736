import { Directive, HostListener, Input } from '@angular/core';
import { DeviceInfoService } from 'src/app/_core/services/device-info.service';

@Directive({
  selector: '[appCarouselHover]'
})
export class CarouselHoverDirective {

  constructor(private deviceInfoService: DeviceInfoService) { }

  @Input() index: number | undefined;
  @Input() keyId: string | undefined;
  @Input() isHover: boolean = false;
  @Input() isNormalTransition: boolean | undefined;
  @Input() imgWidth: number;
  @Input() gap: number;

  @HostListener('mouseenter') onMouseEnter() {

    if (this.isHover && this.deviceInfoService.getDeviceType() != 'MOBILE') {
      let id = `${this.keyId}hoverContainer${this.index}`;
      var element: any = document.getElementById(id);
      var imageElement = document.getElementById(`${this.keyId}img${this.index}`);
      var carouselContainerElement = document.getElementById(`${this.keyId}carousel-container`);
      let left = imageElement.offsetLeft - carouselContainerElement.scrollLeft;

      setTimeout(() => {
        let min = ((this.imgWidth + this.gap) * (this.index + 1)) - 50 - carouselContainerElement.clientWidth;
        let max = carouselContainerElement.clientWidth - (this.imgWidth + this.gap) + min;

        if ((carouselContainerElement.scrollLeft > min && carouselContainerElement.scrollLeft < max) || (this.index == 0 && carouselContainerElement.scrollLeft == 0)) {
          if (this.isNormalTransition) {
            element.classList.remove("normal-transition");
            element.classList.remove("left-transition");
            element.classList.remove("right-transition");
            element.classList.add("show-hover-container");
          } else {
            element.classList.remove("normal-transition-special");
            element.classList.remove("left-transition-special");
            element.classList.remove("right-transition-special");
            element.classList.add("show-hover-container-special");
          }
          element.style.left = `${left}px`
          setTimeout(() => {
            if (this.isNormalTransition) {
              switch (true) {
                case min + 75 > carouselContainerElement.scrollLeft:
                  element.classList.add("right-transition");

                  break;
                case max - 50 < carouselContainerElement.scrollLeft:
                  element.classList.add("left-transition");

                  break;
                default:
                  element.classList.add("normal-transition");
                  break;
              }
            } else {

              switch (true) {
                case min + 75 > carouselContainerElement.scrollLeft:
                  element.classList.add("right-transition-special");
                  break;
                case max - 50 < carouselContainerElement.scrollLeft:
                  element.classList.add("left-transition-special");
                  break;
                default:
                  element.classList.add("normal-transition-special");
                  break;
              }
            }
          }, 500);
        }
      }, 500);
    } else {
      let element = document.getElementById(`${this.keyId}viewMore${this.index}`);
      if (element) {
        element.style.opacity = "0.5";
        element.style.display = "grid";
      }
    }
  }
  @HostListener('mouseleave') onMouseLeave() {
    if (this.isHover) {
      setTimeout(() => {
        let id = `${this.keyId}hoverContainer${this.index}`;
        var element: any = document.getElementById(id);
        if (!!element) {
          if (this.isNormalTransition) {
            element.classList.remove("show-hover-container");
            element.classList.remove("normal-transition");
            element.classList.remove("left-transition");
            element.classList.remove("right-transition");
          } else {
            element.classList.remove("show-hover-container-special");
            element.classList.remove("normal-transition-special");
            element.classList.remove("left-transition-special");
            element.classList.remove("right-transition-special");
          }
        }
      }, 500);
    } else {
      let element = document.getElementById(`${this.keyId}viewMore${this.index}`);
      if (element) {
        element.style.opacity = "0";
        element.style.display = "none";
      }
    }
  }
}
