import { AfterViewInit, Component, HostListener, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthenticationService } from 'src/app/_core/services/authentication.service';
import { CommonService } from 'src/app/_core/services/common.service';
import { DeviceInfoService } from 'src/app/_core/services/device-info.service';
import { HelperService } from 'src/app/_core/services/helper.service';
import { HomeReportProblemComponent } from '../home-report-problem/home-report-problem.component';
import { RequestEmailModalComponent } from '../request-email-modal/request-email-modal.component';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit, AfterViewInit{

  pageYoffset: any;

  country:any;
  isSearchUrl: boolean;

  navItems: any[] = [];

  isReport: boolean = false;

  isMobileView:boolean = false;
  constructor(
    private deviceInfoService: DeviceInfoService,
    private router: Router,
    private authenticationSerice: AuthenticationService,
    private spinnerService: NgxSpinnerService,
    private helperService: HelperService,
    private commonService: CommonService,
    private matDialog: MatDialog
    ) { }

  @HostListener('window:scroll', ['$event']) onScroll(event){
    this.pageYoffset = window.pageYOffset;
    if(this.pageYoffset > 100) {
      document.getElementById('float-btn').style.display = 'block'
    } else {
      document.getElementById('float-btn').style.display = 'none'
    }
  }

  ngOnInit(): void {
    if(this.router.url == '/other/search/view') {
      this.isSearchUrl = true;
    }
    if((this.deviceInfoService.getDeviceType() == 'MOBILE' || this.deviceInfoService.getDeviceType() == 'TABLET') && !this.router.url.startsWith('/account/')) {
      this.isMobileView = true;
    }
    this.loadingNavItems()
    setTimeout(() => {
      this.spinnerService.show('footer');
      this.authenticationSerice.getCountry().subscribe(res=> {
        this.country = res.names.en;
        this.spinnerService.hide('footer')
      }, (err) => {
        this.spinnerService.hide('footer')
      })
    }, 200);
    if(this.router.url.split('/')[1]=='other') {
      this.isReport = true;
    }
  }


  ngAfterViewInit() {
    var ele = document.getElementById('topbtn');
    if(ele) {
      ele.addEventListener('click', (e) => {
        e.preventDefault();
        window.scrollTo({top: 0, behavior: 'smooth'});
      })
    }
  }

  loadingNavItems() {
    this.commonService.getMenu()
    .subscribe((screens: any) => {
      this.navItems = screens.map((screen: any) => {
        return {
          name: screen.name,
          screenId: screen.id
        };
      });
    })
  }

  navigateToSetting() {
    this.router.navigate(['/settings/view']);
  }
  navigateToHome() {
    this.router.navigate(['/home']);
  }

  navigateToMyList() {
    this.router.navigate(['/my-list']);
  }

  navigateToSearch() {
    this.router.navigate(['/other/search/view'])
  }

  scrollToTop(){
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    })
  }

  //navigations

  navigateTv() {
    if(!this.authenticationSerice.currentUser() && this.router.url.split('/')[1] != 'account') {
      sessionStorage.setItem('roku', '/external/tv');
    }
    this.router.navigate(['/external/tv'])
  }

  navigate(page, index) {
    if(this.router.url.split('/')[1] == 'account') {
      this.router.navigate([this.helperService.changeRouterLink(page.name)]);
    } else {
      this.navItems.forEach((element: any, index: number) => {
        var element: any = document.getElementById(`${element.name}${index}`);
        element.style.borderColor = "transparent";
      });
      var element2: any = document.getElementById(`${page.name}${index}`);
      element2.style.borderColor = "#0087A5";

      this.router.navigate([this.helperService.changeRouterLink(page.name)]);
      if (page.screenId != -1) {
        this.helperService.updateMainComponent(page.screenId);
      }
    }
  }

  openReportDialog() {
    let user = this.authenticationSerice.currentUser()
    if(user.email == null) {
      this.openEmailRequestModal(user)
    } else {
      this.matDialog.open(HomeReportProblemComponent, {panelClass: 'custom-dialog', width: '500px' })
        .afterClosed().subscribe(
          (res) => {
          }
        )
    }
  }

  openEmailRequestModal(user) {
    return this.matDialog.open(RequestEmailModalComponent, {data: {id: user.user_id, message: 'Please enter your email before proceed to the report problem'}, disableClose: true, width: '400px'})
  }

  navigateBiling() {
    this.router.navigate(['/other/legal/billing'])
  }

  navigateHelp() {
    this.router.navigate(['/help-center/main'])
  }
  navigateFaq() {
    this.router.navigate(['/other/legal/faq'])
  }

}
