import { Component, HostListener, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Register } from 'src/app/_core/model/Register';
import { AuthenticationService } from 'src/app/_core/services/authentication.service';
import { DataService } from 'src/app/_core/services/data.service';
import { ScriptService } from 'src/app/_core/services/script.service';

@Component({
  selector: 'app-new-account-page',
  templateUrl: './new-account-page.component.html',
  styleUrls: ['./new-account-page.component.scss']
})
export class NewAccountPageComponent implements OnInit {

  register: Register = new Register();
  pwdChecked: string;
  returnUrl: string = '';
  userData: any;
  selectedAvatar: string;
  isSocialLogin: boolean = false;
  user:any

  tempRegister:any;
  isTempUser:boolean = false;

  matureSet:boolean = false;
  avatartList: any;
  isAvatarApi: boolean = false;

  newAccountForm: FormGroup;
  genderError: string = ''
  passwordFieldType: boolean;
  confirmPasswordFieldType: boolean;

  constructor(
    private route: ActivatedRoute, 
    private authenticationService: AuthenticationService,
    private router: Router,
    private dataService: DataService,
    private spinnerService: NgxSpinnerService,
    private scriptService: ScriptService,
    private fb: FormBuilder
  ) { }

  @HostListener('window:popstate', ['$event'])
  onPopState(event) {
    this.dataService.passAccountStatus('')
  }

  ngOnInit() {
    this.user = this.authenticationService.currentUser();
    this.createForm();
    this.scriptService.load('draggable').then(e => {
        this.getAvatars()
      })
    if(this.user != null) {
      if(this.user.login_type == 'GOOGLE' || this.user.login_type == 'FACEBOOK') {
        this.isSocialLogin = true;
        this.register.name = this.user.name;
        this.dataService.passCompleteStep(1);
      }
      if(this.isSocialLogin) {
        this.name.disable();
        this.name.clearValidators();
        this.name.updateValueAndValidity();
        this.password.disable()
        this.register.password = ""
        this.password.clearValidators();
        this.password.setErrors(null)
        this.password.updateValueAndValidity();
        this.confirmPassword.disable();
        this.register.password_confirmation = ""
        this.confirmPassword.clearValidators();
        this.confirmPassword.setErrors(null)
        this.confirmPassword.updateValueAndValidity();
      }
    }
    if(this.route.snapshot.queryParams.data){
      this.userData = JSON.parse(window.atob(this.route.snapshot.queryParams.data));
      this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/home';
    }
    setTimeout(() => {
      this.spinnerService.show('new-account')
      this.dataService.passAccountStatus('PACKAGE');
      this.spinnerService.hide('new-account')
    }, 500);

    if(sessionStorage.getItem('register')) {
      this.isTempUser = true;
      this.tempRegister = JSON.parse(sessionStorage.getItem('register'));
      this.register.name = this.tempRegister.name;
      this.register.password = this.tempRegister.password;
      this.register.password_confirmation = this.tempRegister.password_confirmation;
      this.register.gender_type = this.tempRegister.gender_type;
      this.register.age = this.tempRegister.age;
      if(this.register.avatar_id) {
        this.setAvatar(this.tempRegister.avatar_id)
      }
    }
  }


  createForm() {
    this.newAccountForm = this.fb.group({
      name: new FormControl('', [Validators.required, Validators.minLength(3)]),
      password: new FormControl('', [Validators.required, Validators.minLength(6)]),
      confirmPassword: new FormControl('', [Validators.required, Validators.minLength(6)]),
      age: new FormControl('', [Validators.required, Validators.pattern('^[0-9]*$'), Validators.min(18)])
    }, 
    {
      validator: this.matchPassword('password', 'confirmPassword')
    })
  }

  get name() {
    return this.newAccountForm.get('name');
  }
  get password() {
    return this.newAccountForm.get('password');
  }
  get confirmPassword() {
    return this.newAccountForm.get('confirmPassword');
  }
  get age() {
    return this.newAccountForm.get('age');
  }

  submit() {
    this.spinnerService.show('new-account')
    if(this.register.gender_type == null) {
      this.genderError = 'Please select gender';
      setTimeout(() => {
        this.spinnerService.hide('new-account')
      }, 200);
    }
    if(sessionStorage.getItem('register') && this.register.avatar_id == null) {
      this.register.avatar_id = this.tempRegister.avatar_id
    }
    this.register.age = parseInt(this.register.age)
    this.authenticationService.registerUser(this.register, this.userData.id)
      .subscribe(
        (res) => {
          this.getProfiles(res);
          this.setSignCookies();
          sessionStorage.setItem('register', JSON.stringify(this.register))
          this.spinnerService.hide('new-account')
        },
      (error) => {
        this.spinnerService.hide('new-account')
      });
  }

  getAvatars(){
    this.authenticationService.getAvatars()
      .subscribe(
        (res) => {
          this.isAvatarApi = true;
          this.avatartList = res.filter(a => a.avatar_id != 77);
        }, (err) => {
          this.isAvatarApi = false;
        });
  }

  reSubmit() {
    this.openPackageSelectDialog();
  }

  selectGender(gender) {
    this.genderError = ''
    this.register.gender_type = gender;
  }

  selectAvatar(item) {
    this.register.avatar_id = item.avatar_id;
  }

  setAvatar(id){
    this.authenticationService.getAvatars()
      .subscribe(
        (res) => {
          let result = res.find(avatar => avatar.avatar_id == id);
          document.getElementById("addAvatar").setAttribute("src", `${result.avatar_url}`);
        });
  }

  getProfiles(data) {
    this.authenticationService.getAllProfileByUserId(data.user_id)
      .subscribe(
        (res) => {
          this.authenticationService.setProfileCookie(res[0]);
          this.openPackageSelectDialog();
        });
  }
  setSignCookies() {
    this.authenticationService.getSignedCookies();
  }
  openPackageSelectDialog() {
    this.dataService.passAccountStatus('COMPLETED');
    let encoded = {
      id: this.userData.id,
      packageId: 1,
      isNew: true,
      account: true,
      profile: true,
      package: false
    }
    this.dataService.passCompleteStep(2)
    let queryData = window.btoa(JSON.stringify(encoded))
    this.router.navigate(['/account/package-select'], {queryParams: {data: queryData,returnUrl: this.returnUrl }})
  }


  scrollRight() {
    var t = document.getElementById('alist');
    t.scrollLeft += 50;
  }

  scrollLeft() {
    var t = document.getElementById('alist');
    t.scrollLeft -= 50;
  }

  togglePasswordFieldTextType() {
    this.passwordFieldType = !this.passwordFieldType;
  }

  toggleConfirmPwdFieldTextType() {
    this.confirmPasswordFieldType = !this.confirmPasswordFieldType;
  }

  matchPassword(password: string, confirmPassword: string) {
    return (formGroup: FormGroup) => {
      const passwordControl = formGroup.controls[password];
      const confirmPasswordControl = formGroup.controls[confirmPassword];

      if (!passwordControl || !confirmPasswordControl) {
        return null;
      }

      if (confirmPasswordControl.errors && !confirmPasswordControl.errors.passwordMismatch) {
        return null;
      }

      if (passwordControl.value !== confirmPasswordControl.value) {
        confirmPasswordControl.setErrors({ passwordMismatch: true });
      } else {
        confirmPasswordControl.setErrors(null);
      }
    }
  }
  
  
}
