import { Component, Inject, NgZone, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-screen-exceed-warning',
  templateUrl: './screen-exceed-warning.component.html',
  styleUrls: ['./screen-exceed-warning.component.scss']
})
export class ScreenExceedWarningComponent implements OnInit {

  screens: any[] = [];
  removeScreens: String[] = [];

  constructor(
    public dialogRef: MatDialogRef<ScreenExceedWarningComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private ngZone: NgZone
  ) {
    this.screens = data;
  }

  ngOnInit() {
  }
  addToRemoveList(event, screen) {
    if (event.target.checked) {
      this.removeScreens.push(screen.id);
    } else {
      let index = this.removeScreens.indexOf(screen.id);
      if (index != -1) {
        this.removeScreens.splice(index, 1);
      }
    }
  }
  submit() {
    this.ngZone.run(() => {
      this.dialogRef.close(this.removeScreens);
    })
  }

}
