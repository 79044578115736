import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from './authentication.service';
import { DeviceInfoService } from './device-info.service';
import { HttpService } from './http/http.service';

@Injectable({
  providedIn: 'root'
})
export class ContentService {

  constructor(
    private http: HttpService,
    private deviceInfoService: DeviceInfoService,
    private authectivationService: AuthenticationService
  ) { }


  getContentBySlug(slug: string, content: string, date?: string): Observable<any> {
    if (!!date) {
      return this.http.get<any>(`${environment.contentDetailServiceUrl}${content}/${slug}?country-id=1&device-type=${this.deviceInfoService.getDeviceType()}&date=${date}`);
    } else {
      return this.http.get<any>(`${environment.contentDetailServiceUrl}${content}/${slug}?country-id=1&device-type=${this.deviceInfoService.getDeviceType()}`);
    }
  }
  getRelatedMovies(page: number, size: number, keywords: string) {
    return this.http.get<any>(`${environment.contentDetailServiceUrl}movies/related-movies?country-id=1&device-type=${this.deviceInfoService.getDeviceType()}${keywords}page=${page}&size=${size}`);
  }
  getTvEpisodesBySeasons(page: number, size: number, seasonId: number, slug: string, contentType: string) {
    let videoType: string = (contentType == "tv-shows") ? "tv-episodes" : "videos";
    return this.http.get<any>(`${environment.contentDetailServiceUrl}${contentType}/${videoType}/${slug}/${seasonId}?country-id=1&device-type=${this.deviceInfoService.getDeviceType()}&page=${page}&page-size=${size}`);
  }
  getRelatedContent(content: string, contentId: number, keyWords: string, page: number, size: number) {
    if (content === environment.contentTypesForRq.movie)
      return this.http.get<any>(`${environment.contentDetailServiceUrl}${content}/related-movies?content-id=${contentId}&country-id=1&device-type=${this.deviceInfoService.getDeviceType()}${keyWords}&page=${page}&page-size=${size}`);
    if (content === environment.contentTypesForRq.tvShow)
      return this.http.get<any>(`${environment.contentDetailServiceUrl}${content}/related-tv-shows?content-id=${contentId}&device-type=${this.deviceInfoService.getDeviceType()}${keyWords}&page=${page}&page-size=${size}`);
    // todo
    if(content === environment.contentTypesForRq.video)
      return this.http.get<any>(`${environment.contentDetailServiceUrl}tv-shows/related-video-channel?content-id=${contentId}&device-type=${this.deviceInfoService.getDeviceType()}${keyWords}&page=${page}&page-size=${size}`);
  }
  getEpisodeDetailBySlug(slug: string) {
    return this.http.get<any>(`${environment.contentDetailServiceUrl}tv-episodes/${slug}?country-id=1&device-type=MOBILE`);
  }
  addToWatchList(profileId: number, body: any) {
    return this.http.post(environment.contentDetailServiceUrl + 'profiles/' + profileId + '/watchlist', body);
  }
  getCastDetailsById(id: number): Observable<any> {
    return this.http.get<any>(`${environment.contentDetailServiceUrl}cast-members/${id}`);
  }
  getCastContentByCastId(id: number, page: number, size: number): Observable<any> {
    let profileId: string = '';
    let profile = this.authectivationService.selectedProfile();
    if (!!profile) {
      profileId = `&profile-id=${profile.id}`;
    }
    if(profile) {
      return this.http.get<any>(`${environment.contentDetailServiceUrl}cast-members/${id}/contents?device-type=${this.deviceInfoService.getDeviceType()}&page=${page}&page-size=${size}${profileId}`);
    } else {
      return this.http.get<any>(`${environment.contentDetailServiceUrl}cast-members/${id}/contents?device-type=${this.deviceInfoService.getDeviceType()}&page=${page}&page-size=${size}${profileId}`);
    }
  }
  getProblems(page, size): Observable<any> {
    return this.http.get<any>(`${environment.contentDetailServiceUrl}problems?page=${page}&size=${size}`);
  }
  reportProblem(body: any, userId): Observable<any> {
    return this.http.post<any>(`${environment.contentDetailServiceUrl}users/${userId}/problems`, body);
  }
  homeReportProblem(body: any): Observable<any> {
    return this.http.post<any>(`${environment.contentDetailServiceUrl}users/problems`, body)
  }
  getAddUrl(contentId: number, endCuePoint: string, duration: number): Observable<any> {
    let currentUser = this.authectivationService.currentUser();
    let currentProfile = this.authectivationService.selectedProfile();

    let userId = currentUser.user_id
    let age = (currentProfile.age) ? `&age=${currentProfile.age}` : ""
    let gender = (currentProfile.gender_type) ? `&gender=${currentProfile.gender_type}` : "";

    return this.http.get<any>(`${environment.addServiceUrl}serveAds?contentId=${contentId}&contentDuration=${duration}&userId=${userId}${age}${gender}${endCuePoint}`);
  }
}
