import { Component, HostListener, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthenticationService } from 'src/app/_core/services/authentication.service';
import { DataService } from 'src/app/_core/services/data.service';
import { environment } from 'src/environments/environment';
import { ProfileComponent } from '../../profile/profile.component';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss']
})
export class LoginPageComponent implements OnInit{

  returnUrl: string;
  isLoginUsingEmail: boolean = true;
  userName: string;
  password: string;
  userErrorMessage: string;
  passErrorMessage:string;
  userNameValidated:boolean = false;
  isNewUser:boolean = false;
  isGoogleUser: boolean = false;
  isEmailUser: boolean = false;


  isSignIn:boolean = true;
  isOtp:boolean = false;

  otp: number[] = [null, null, null, null];
  isArrowKey: boolean = false;
  otpViewUserName: string;
  otpData: any;
  otpErrorMessage: string;
  setInterval: any;
  time: number = 0;
  previousUrl: string;

  recaptchaToken: string = null
  isRecaptcha: boolean = false;

  social_error: string = null

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private matDialog: MatDialog,
    private dataService: DataService,
    private spinnerService: NgxSpinnerService
  ) {

  }

  @HostListener('window:popstate', ['$event'])
  onPopState(event) {
    clearInterval(this.setInterval)
  }

  ngOnInit() {
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/home';
    setTimeout(() => {
      this.spinnerService.show('login')
      this.dataService.passAccountStatus('PROFILE');
      this.spinnerService.hide('login')
    }, 500);
    if(sessionStorage.getItem('social')) {
      this.social_error = JSON.parse(sessionStorage.getItem('social')).error.error;
      this.isEmailUser = true;
      sessionStorage.removeItem('social')
    }
  }

  changeUserName(username: any){
    if(this.isNumeric(username)){
      this.isLoginUsingEmail = false;
    }else{
      this.isLoginUsingEmail = true;
    }
  }
  isNumeric(val) {
    return /^-?\d+$/.test(val);
  }

  continueLogin(form){
    this.social_error = null;
    this.spinnerService.show('login')
    this.otpData = {};
    if(!this.isLoginUsingEmail){
      while(this.userName.charAt(0) === '0')
      {
        this.userName = this.userName.substring(1);
      }
    }
    var credential = (this.isLoginUsingEmail) ? this.userName : `94${this.userName}`;//hard code the 94 value until phone validation library is fixed
    this.userErrorMessage = null;
    if(this.isLoginUsingEmail){
      if(credential) {
        if (!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(credential.trim()))){
          this.userErrorMessage = "Invalid email"
          this.spinnerService.hide('login')
          return false;
        }
      } else {
        this.spinnerService.hide('login')
      }
    }else{
      if(credential.length !=11
        || (!credential.startsWith('9470')
        && !credential.startsWith('9471')
        && !credential.startsWith('9472')
        && !credential.startsWith('9474')
        && !credential.startsWith('9475')
        && !credential.startsWith('9476')
        && !credential.startsWith('9477')
        && !credential.startsWith('9478')
        && !credential.startsWith('9479'))
      ){
        this.userErrorMessage = "Invalid phone number"
        this.spinnerService.hide('login')
        return false;
      }
    }
    if(form.value.username) {
      this.validateUsername(credential.trim());
    } else {
      this.userErrorMessage = "Please enter valid email of mobile number"
      this.spinnerService.hide('login')
    }
  }

  validateUsername(credential) {
    this.otpData = null;
    this.authenticationService.validateUser(credential)
      .subscribe(
        (res) => {
          switch (res.user_account_status) {
            case 'PROFILE':
              let encoded = {
                id: res.user_id,
                username: res.username,
                isNew: true,
                account: true,
                // profile: true,
                packageId: 1
              }
              let queryData = window.btoa(JSON.stringify(encoded))
              this.dataService.isStepperAvailable(true)
              this.router.navigate(['/account/new-account/creation'], {queryParams: {data: queryData, returnUrl: this.returnUrl}});
              break;
            case 'PACKAGE':
              let encodedPkg = {
                id: res.user_id,
                username: res.username,
                isNew: true,
                account: true,
                profile: true,
                // package: true,
                packageId: 1
              }
              let queryDataPkg = window.btoa(JSON.stringify(encodedPkg))
              this.dataService.isStepperAvailable(true)
              this.router.navigate(['/account/package-select'], {queryParams: {data: queryDataPkg, returnUrl: this.returnUrl}});
              break;
          }
          if(res.login_type == 'GOOGLE') {
            this.isGoogleUser = true;
          }
          if(res.login_type == 'EMAIL') {
            this.isEmailUser = true;
          }
          this.userNameValidated = true;
          this.userName = this.isLoginUsingEmail ? credential : `94${credential.slice(2)}`;
          setTimeout(() => {
            var pwd = document.getElementById('pwd')
            pwd ? pwd.focus() : null;
          }, 500);
          this.spinnerService.hide('login')

        },
        (error) => {
          this.spinnerService.hide('login');
          switch (error.error.error) {
            case `no user found with username ${credential}`:
            case "OTP verification is PENDING":
              this.otpViewUserName = this.isLoginUsingEmail ? credential : `94${credential.slice(2)}`;
              this.otpData = {
                login_type: (this.isLoginUsingEmail) ? environment.loginTypeOtp.email : environment.loginTypeOtp.mobile,
                username: this.otpViewUserName,
                type: 'new-user',
              }
              this.isNewUser = true;
              this.dataService.isStepperAvailable(true)
              this.dataService.passAccountStatus('PROFILE')
              break;
            case "User registration is PENDING":
              this.userErrorMessage = `${error.error.error}. You will be redirect to Registration Process.`;
              break;
            default:
              this.userErrorMessage = error.error.error;
              break;
          }
        });
  }

  resolved(captchaResponse: string) {
    try {
      this.spinnerService.show('login')
      this.recaptchaToken = captchaResponse;
      setTimeout(() => {
        if(this.recaptchaToken != null) {
          this.isSignIn = false;
          this.isOtp  = true;
          this.selectRequestType();
        }
      }, 200);
    } catch (error) {
      throw error;
    }
  }

  userLogin(){
    this.spinnerService.show('login')
    this.passErrorMessage = null;
    let data = {
      login_type: (this.isLoginUsingEmail) ? environment.loginTypeSignIn.email : environment.loginTypeSignIn.mobile,
      username: this.userName,
      password: this.password
    }
    if(data.password) {
      this.authenticationService.signIn(data)
        .subscribe(
          (res: any) => {
            if(res) {
              this.getProfiles(res);
              this.setSignCookies();
              this.spinnerService.hide('login')
            }
          },
          (error) => {
            this.passErrorMessage = (error.error && error.error.error) ? error.error.error : "Something went wrong";
            this.spinnerService.hide('login')
          });
    } else {
      this.spinnerService.hide('login')
      this.passErrorMessage = 'Please enter password'
    }
  }

  setSignCookies() {
    this.authenticationService.getSignedCookies();
  }

  getProfiles(data) {
    this.authenticationService.getAllProfileByUserId(data.user_id)
      .subscribe(
        (res) => {
          if (res.length > 1) {
            this.openProfileDialog(res);
          } else {
            this.authenticationService.setProfileCookie(res[0]);
            this.dataService.refreshLoginCredential(false);
            if(sessionStorage.getItem('roku')) {
              this.router.navigate([sessionStorage.getItem('roku')])
              sessionStorage.removeItem('roku')
            } else {
              this.dataService.fireUser.subscribe(res => {
                if(res && this.returnUrl) {
                  this.router.navigate([this.returnUrl]).then(() => {
                    window.location.reload()
                  })
                }
              })
            }
          }
      });
  }

  openProfileDialog(res) {
    this.matDialog.open(ProfileComponent, { disableClose: true, data: { profiles: res } })
      .afterClosed().subscribe(
        (res) => {
          if(sessionStorage.getItem('roku')) {
            this.router.navigate([sessionStorage.getItem('roku')])
            sessionStorage.removeItem('roku')
          } else {
            if(this.returnUrl) {
              this.router.navigate([this.returnUrl])
                .then(() => {
                  window.location.reload()
                })
            }
          }
      })
  }

  loginUsingSocialMedia(type: string) {
    this.authenticationService.getSocialMediaAuthUrl(type)
      .subscribe(
        (res) => {
          sessionStorage.setItem('google_redirect_url', this.returnUrl);
          window.location.href = res.authorization_url;
        }
      ), (err) => {
        // console.log('social',err)
      };
  }

  forgotPassword() {
    this.otpData = {
      login_type: (this.isLoginUsingEmail) ? environment.loginTypeOtp.email : environment.loginTypeOtp.mobile,
      username: this.userName,
      type: 'password-reset',
    };
    this.otpViewUserName = this.otpData.username
    this.isSignIn = false;
    this.isOtp  = true;
    this.selectRequestType();
  }

  //otp fucntions

  validateKeyUp(nextId, event, prevId, nextIndex) {
    const code = event.keyCode | event.which;
    var nextElement;
    var previousElement;
    if (nextId) {
      nextElement = document.getElementById(nextId);
    }
    if (prevId) {
      previousElement = document.getElementById(prevId);
    }

    switch (true) {
      case (code == 8)://back space code
        break;
      case (code == 37)://left code
        setTimeout(() => { previousElement.focus(); }, 0);
        this.isArrowKey = true;
        break;
      case (code == 39)://right code
        setTimeout(() => { nextElement.focus(); }, 0);
        this.isArrowKey = true;
        break;
      case ((96 <= code && code <= 105) || (48 <= code && code <= 57)): //number code
        if (nextElement && event.target.value) {
          setTimeout(() => { nextElement.focus(); }, 0);
        }
        if (!this.isArrowKey) {
          this.otp[nextIndex] = null;
        }
      default:
        this.validateOTP(this.otpData);
        break;
    }
  }

  onPaste(e) {
    let data = e.clipboardData.getData('text').split('');
    [].forEach.call(document.querySelectorAll(".otp-codev"), (node, index) => {
      node.value = data[index];
    });
    let body = {
      otp: data.join("").trim(),
      username: this.otpData.username
    }
    this.otpErrorMessage = null;
    if (this.otpData.type == "new-user")
      this.verifyNewOTP(body);
    if (this.otpData.type == "password-reset")
      this.verifyPasswordResetOTP(body);
  }


  validateOTP(data) {
    if ((!!this.otp[0] || this.otp[0] == 0) && (!!this.otp[1] || this.otp[1] == 0) && (!!this.otp[2] || this.otp[2] == 0) && (!!this.otp[3] || this.otp[3] == 0)) {
      let body = {
        otp: this.otp.join(""),
        username: data.username
      }
      this.otpErrorMessage = null;
      if (this.otpData.type == "new-user")
        this.verifyNewOTP(body);
      if (this.otpData.type == "password-reset")
        this.verifyPasswordResetOTP(body);
    }
  }
  verifyPasswordResetOTP(body) {
    this.spinnerService.show('login')
    this.authenticationService.verifyPasswordResetOtp(body)
      .subscribe(
        (res) => {
          let data = {
            verification_code: res.verification_code,
            username: this.userName
          }
          let queryData = window.btoa(JSON.stringify(data))
          setTimeout(() => {
            this.router.navigate(['/account/reset-password'], {queryParams: {data: queryData}});
            this.spinnerService.hide('login')
          }, 500)
        },
        (error) => {
          this.otpErrorMessage = error.error.error;
          this.spinnerService.hide('login')
        });
  }
  verifyNewOTP(body) {
    this.spinnerService.show('login')
    this.authenticationService.verifyNewOtp(body)
      .subscribe(
        (res) => {
          let encoded = {
            id: res.user_id,
            username: res.username,
            account: true,
            profile: false,
            packageId: 1
          }
          this.dataService.passCompleteStep(1);
          this.dataService.passAccountStatus('PACKAGE')
          let queryData = window.btoa(JSON.stringify(encoded))
          setTimeout(() => {
            this.router.navigate(['/account/new-account/creation'], {queryParams: {data: queryData, returnUrl: this.returnUrl}});
            this.spinnerService.hide('login')
          }, 500);
        },
        (error) => {
          this.otpErrorMessage = error.error.error;
          this.spinnerService.hide('login')
        });
  }

  selectRequestType() {
    switch (this.otpData.type) {
      case 'new-user':
        this.sendNewUserOtp();
        break;
      case 'password-reset':
        this.sendPasswordResetOtp();
        break;
      default:
        break;
    }
  }
  // selectRequestType() {
  //   this.otpData = {
  //     login_type: (this.isLoginUsingEmail) ? environment.loginTypeOtp.email : environment.loginTypeOtp.mobile,
  //     username: this.userName,
  //     type: 'password-reset',
  //   };
  //   this.otpViewUserName = this.otpData.username
  //   this.isSignIn = false;
  //   this.isOtp  = true;
  //   this.sendPasswordResetOtp();
  //   //     break;
  //   //   default:
  //   //     break;
  //   // }
  // }



  sendNewUserOtp() {
    this.otpErrorMessage = ''
    let body = { login_type: this.otpData.login_type };
    body[`${this.otpData.login_type}`] = this.otpData.username;
    body['recaptcha_response'] = this.recaptchaToken;
    body['device_type'] = 'WEB'

    this.authenticationService.createNewOtp(body)
      .subscribe(
        (res) => {
          this.countdown();
          this.spinnerService.hide('login')
        },
        (error) => {
          this.otpErrorMessage = error.error.error;
          clearInterval(this.setInterval)
        });
  }

  sendPasswordResetOtp() {
    this.otpErrorMessage = ''
    let body = { login_type: this.otpData.login_type };
    body[`${this.otpData.login_type}`] = this.otpData.username;
    body['recaptcha_response'] = this.recaptchaToken;
    body['device_type'] = 'WEB'

    this.authenticationService.passwordResetOtp(body)
      .subscribe(
        (res) => {
          this.countdown();
          this.spinnerService.hide('login')
        },
        (error) => {
          this.otpErrorMessage = error.error.error;
          clearInterval(this.setInterval)
        });
  }

  countdown() {
    const temp = this;
    temp.time = 60;
    this.setInterval = setInterval(function () {
      if (temp.time == 0) {
        clearInterval(temp.setInterval);
      }
      temp.time -= 1;
      var res = document.getElementById('resend')
      var otp = document.getElementById('otpdiv')
      var time = document.getElementById('time')
      if(temp.time <= 0) {
        res ? res.style.display = 'block' : null;
        otp ? otp.style.display = 'none' : null;
      } else {
        res ? res.style.display = 'none' : null
        otp ? otp.style.display = 'block' : null;
        time ? time.innerHTML = temp.time.toString() : null;
      }
    }, 1000)
  }

  enableInput() {
    this.isNewUser = false
  }


}
