import {NgModule, OnInit} from '@angular/core';
import {Routes, RouterModule, Router} from '@angular/router';
import { HeaderComponent } from './entry/header/header.component';
import { PaymentMainComponent } from './entry/payment-main/payment-main.component';


const routes: Routes = [
  { path: '', component: HeaderComponent, loadChildren: () => import('./entry/entry.module').then(m => m.EntryModule) },
  { path: 'external', loadChildren: () => import('./external/external.module').then(m => m.ExternalModule) },
  { path: 'account', loadChildren: () => import('./account/account.module').then(m => m.AccountModule) },
  { path: 'settings', loadChildren: () => import('./settings/settings.module').then(m => m.SettingsModule) },
  { path: 'payment', component: PaymentMainComponent, loadChildren: () => import('./payment/payment.module').then(m => m.PaymentModule) },
  { path: 'player', loadChildren: () => import('./player/player.module').then(m => m.PlayerModule) },
  { path: 'help-center', loadChildren: () => import('./help-center/help.module').then(m => m.HelpModule) },
  { path: '**', redirectTo: 'other/page-not-found', pathMatch: 'full'},
  // { path: this.fbRedirect(), redirectTo:''}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top' })],
  exports: [RouterModule]
})
export class AppRoutingModule implements OnInit{

  constructor(private router: Router) {}

  ngOnInit(): void {

  }
}
