import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../_core/auth-guard/auth.guard';
import { MainComponent } from './main/main.component';
import { WatchListComponent } from './watch-list/watch-list.component';

const routes: Routes = [
  { path: 'my-list', component: WatchListComponent, canActivate: [AuthGuard] },
  { path: ':name', component: MainComponent },
  { path: 'other', loadChildren: () => import('../other/other.module').then(m => m.OtherModule) }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class EntryRoutingModule { }
