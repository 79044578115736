import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { AuthenticationService } from 'src/app/_core/services/authentication.service';

@Component({
  selector: 'app-change-avatar',
  templateUrl: './change-avatar.component.html',
  styleUrls: ['./change-avatar.component.scss']
})
export class ChangeAvatarComponent implements OnInit {

  constructor(
    private authenticationService: AuthenticationService,
    public dialogRef: MatDialogRef<ChangeAvatarComponent>
  ) { }

  avatar_images: any = null;

  ngOnInit() {
    this.getAvatars();
  }

  getAvatars(){
    this.authenticationService.getAvatars()
      .subscribe(
        (res) => {
          this.avatar_images = res;
        });
  }

  selectAvatar(avatar: any){
    this.dialogRef.close(avatar);
  }

}
