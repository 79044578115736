import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthenticationService } from 'src/app/_core/services/authentication.service';

@Component({
  selector: 'app-request-email-modal',
  templateUrl: './request-email-modal.component.html',
  styleUrls: ['./request-email-modal.component.scss']
})
export class RequestEmailModalComponent implements OnInit {

  userId: string;
  message: string;
  newEmail: string;
  emailError: string;
  otpError: string;
  otp: number[] = [null, null, null, null];
  isArrowKey: boolean = false;
  isEmailSent: boolean = false;
  emailVerified: boolean = false;

  setInterval: any;
  time: number = 60;

  constructor(
    private authenticationService: AuthenticationService,
    private spinnerService: NgxSpinnerService,
    public dialogRef: MatDialogRef<RequestEmailModalComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
  ) {
    this.userId = data.id
    this.message = data.message
  }

  ngOnInit() {
    var res = document.getElementById('resend')
    var otp = document.getElementById('otpdiv')
    if(res && otp) {
      res.style.display = 'none'
      otp.style.display = 'none'
    }
  }

  submitEmail() {
    this.emailError = '';
    this.spinnerService.show('req-email')
    let body = {
      old_email: this.userId.toString(),
      new_email: this.newEmail,
      login_type: 'email'
    }
    this.authenticationService.requestEmail(body).subscribe(res => {
      this.isEmailSent = true;
      this.spinnerService.hide('req-email')
      this.countdown()
    }, (err) => {
      // this.emailError = err.error.error;
      this.spinnerService.hide('req-email')
    })
  }
  verifyEmail() {
    if ((!!this.otp[0] || this.otp[0] == 0) && (!!this.otp[1] || this.otp[1] == 0) && (!!this.otp[2] || this.otp[2] == 0) && (!!this.otp[3] || this.otp[3] == 0)) {
      let body = {
        username: this.userId,
        otp: this.otp.join("")
      }
      this.authenticationService.verifyRequestEmail(body).subscribe(res => {
        let user = this.authenticationService.currentUser();
        this.spinnerService.show('req-email')
        setTimeout(() => {
          user.email = res.username;
          this.authenticationService.setTokenCookie(user);
          this.spinnerService.hide('req-email')
          this.dialogRef.close()
        }, 2000);
      },(err) => {
        this.spinnerService.hide('req-email')
        this.otpError = err.error.error;

      })
    }
  }

  onPaste(e) {
    let data = e.clipboardData.getData('text').split('');
    [].forEach.call(document.querySelectorAll(".otp-codev"), (node, index) => {
      node.value = data[index];
    });
    let body = {
      username: this.userId,
      otp: data.join("").trim(),
    }
    this.authenticationService.verifyRequestEmail(body).subscribe(res => {
      let user = this.authenticationService.currentUser();
      this.spinnerService.show('req-email')
        setTimeout(() => {
          user.email = res.username;
          this.authenticationService.setTokenCookie(user);
          this.spinnerService.hide('req-email')
          this.dialogRef.close()
        }, 2000);
    },(err) => {
      this.spinnerService.hide('req-email')
      this.emailError = err.error.error;
    })
  }

  validateKeyUp(nextId, event, prevId, nextIndex) {
    const code = event.keyCode | event.which;
    var nextElement;
    var previousElement;
    if (nextId) {
      nextElement = document.getElementById(nextId);
    }
    if (prevId) {
      previousElement = document.getElementById(prevId);
    }

    switch (true) {
      case (code == 8)://back space code
        break;
      case (code == 37)://left code
        setTimeout(() => { previousElement.focus(); }, 0);
        this.isArrowKey = true;
        break;
      case (code == 39)://right code
        setTimeout(() => { nextElement.focus(); }, 0);
        this.isArrowKey = true;
        break;
      case ((96 <= code && code <= 105) || (48 <= code && code <= 57)): //number code
        if (nextElement) {
          setTimeout(() => { nextElement.focus(); }, 0);
        }
        if (!this.isArrowKey) {
          this.otp[nextIndex] = null;
        }
      default:
        this.verifyEmail()
        break;
    }
  }

  close() {
    this.dialogRef.close();
    clearInterval(this.setInterval);
  }

  resend() {
    let body = {
      old_email: this.userId.toString(),
      new_email: this.newEmail,
      login_type: 'email'
    }
    this.authenticationService.requestEmail(body).subscribe(res => {
      this.isEmailSent = true;
      this.countdown()
    }, (err) => {
      this.emailError = err.error.error;
    })
  }

  countdown() {
    const temp = this;
    temp.time = 60;
    this.setInterval = setInterval(function () {
      if (temp.time == 0) {
        clearInterval(temp.setInterval);
      }
      temp.time -= 1;
      var res = document.getElementById('resend')
      var otp = document.getElementById('otpdiv')
      var time = document.getElementById('time')
      if(temp.time <= 0) {
        res ? res.style.display = 'block' : null;
        otp ? otp.style.display = 'none' : null;
      } else {
        res ? res.style.display = 'none' : null
        otp ? otp.style.display = 'block' : null;
        time ? time.innerHTML = temp.time.toString() : null;
      }
    }, 1000)
  }

}
