import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Services } from '../model/service';
import { AuthenticationService } from './authentication.service';
import { DeviceInfoService } from './device-info.service';
import { HttpService } from './http/http.service';
import { ScreensService } from './screens.service';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor(
    private screenService: ScreensService,
    private authenticationService: AuthenticationService,
    private http: HttpService,
    private deviceInfoService: DeviceInfoService
  ) { }

  findAll(service: any, options?: any) {
    switch (service) {
      case Services.SCREENS:
        return this.screenService.getScreens(options.screenId, options.size, options.page);

      case Services.PROFILE:
        return this.authenticationService.getAllProfileByUserId(options.id);
    }
  }
  getMenu() {
    return this.screenService.getMenu();
  }

  findById(service: any, Id: number, options?: any) {
    switch (service) {
      case Services.CATEGORY:
        return this.findCategoryById(Id, options.page, options.size);
    }
  }
  findCategoryById(categoryId: number, page: number, size: number) {
    // TODO Get profile ID from cookie and should append to the request
    return this.http.get<any>(environment.screenServiceUrl + 'categories/' + categoryId + '?limit=' + size +
      '&device-type=' + this.deviceInfoService.getDeviceType() + '&page=' + page);
  }
}
