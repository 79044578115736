import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthenticationService } from 'src/app/_core/services/authentication.service';

@Component({
  selector: 'app-password-reset-page',
  templateUrl: './password-reset-page.component.html',
  styleUrls: ['./password-reset-page.component.scss']
})
export class PasswordResetPageComponent implements OnInit {

  password: string;
  confirmPassword: string;
  errorMessage: string;
  successMessage:string;
  userData: any;
  pwdChecked: string;
  passwordmatch: boolean;

  constructor(private route: ActivatedRoute, 
    private authenticationService: AuthenticationService, 
    private router: Router,
    private spinnerService: NgxSpinnerService
  ) { }

  ngOnInit() {
    if(this.route.snapshot.queryParams.data)
      this.userData = JSON.parse(window.atob(this.route.snapshot.queryParams.data));
  }

  submit() {
    this.errorMessage = '';
    this.spinnerService.show('pwd-reset')
    let body = {
      password: this.password,
      username: this.userData.username,
      verification_code: this.userData.verification_code
    }
    if(this.validateForm()) {
      if(this.password != this.confirmPassword) {
        this.errorMessage = 'Confirm passsword mismatch'
        this.removeValidatioMessage()
      } else {
        this.authenticationService.completePasswordReset(body)
          .subscribe(
            (res) => {
              this.successMessage="Password reset success.please signin again.."
              this.spinnerService.hide('pwd-reset')
              this.router.navigate(['/account/login']);
            },
            (error) => {
              this.errorMessage = error.error.error;
              this.spinnerService.hide('pwd-reset')
            });
      }
    } else {
      this.removeValidatioMessage()
    }
  }


  validateForm() : boolean {
    
    if(this.password == null || this.password.length == 0) {
      this.errorMessage = 'Please enter password';
      return false
    }
    if(this.password.length < 6) {
      this.errorMessage = 'Please enter atleast 6 characters';
      return false
    }
    if(this.confirmPassword == null || this.confirmPassword.length == 0) {
      this.errorMessage = 'Please enter confirm password'
      return false
    }
    if(this.confirmPassword.length < 6) {
      this.errorMessage = 'Please enter atleast 6 characters'
      return false
    }

    return true
  }

  removeValidatioMessage() {
    setTimeout(() => {
      this.errorMessage = ''
      this.spinnerService.hide('pwd-reset')
    }, 2000);
  }

}
