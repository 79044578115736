import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HelperService {

  public refreshSource = new BehaviorSubject(null);
  initialRefresh = this.refreshSource.asObservable();

  constructor() { }

  updateMainComponent(navigationData: any) {
    this.refreshSource.observers = this.refreshSource.observers.slice(this.refreshSource.observers.length - 1);
    this.refreshSource.next(navigationData);
  }

  getRunTime(runTime) {
    let hour = Math.floor(runTime / 60);
    return (runTime) ? ((hour) ? hour + 'hr  ' + runTime % 60 + 'min' : runTime % 60 + 'min') : null;
  }

  mapCategory(screen, page, watching?) {
    return screen.categories.map(x => {
      let categoryData = {
        name: x.name,
        display_type: x.display_type,
        content: x.content
          ? this.mapContent(x.content, this.findOrientationTypeByTemplate(x.display_type))
          : this.mapContent(watching.content, this.findOrientationTypeByTemplate(watching.display_type)),
        isContinue: x.content ? false : true,
        id: x.id,
        has_next_page: x.has_next_page,
        isApiRequested: false,
        currentPage: page,
      }
      return categoryData;
    })
  }

  findOrientationTypeByTemplate(display_type) {
    switch (display_type) {
      case "STANDARD":
        return (window.innerWidth > 600) ? false : true;
      case "ADVERTISEMENT":
        return (window.innerWidth > 600) ? false : true;
      case "FEATURED":
        return (window.innerWidth > 600) ? false : true;
      case "VIDEO":
        return (window.innerWidth > 600) ? false : true;
      case "SPECIAL":
        return (window.innerWidth > 600) ? true : true;
      default:
        return (window.innerWidth > 600) ? false : true;
    }
  }

  mapContent(content, isPotrait) {
    return content.map((y) => {
      let mappedData = {
        fullData: y.fullData ? y.fullData : y,
        url: y.fullData ? y.url : (isPotrait)
          ? this.getPosterUrlByFiltering(y.posters, ["ONE_X", "TWO_X", "THREE_X", "FOUR_X"], "PORTRAIT", true)
          : this.getPosterUrlByFiltering(y.posters, ["ONE_X", "TWO_X", "THREE_X", "FOUR_X"], "LANDSCAPE", true),
        desc: (!!y.description) ? y.description.split("").splice(0, 70).join("") + "..." : '',
        run_time: y.run_time,
        timeWidth: y.timeWidth,
        name: y.name,
        genre: y.genre.length > 2 ? y.genre.slice(0, 2).map(t => { return (t.name) ? t.name : t }) : y.genre,
        isTrailerAvailable: y.bonus_content ? y.bonus_content.cdn_url ? true : false : null,
      }
      return mappedData;
    });
  }

  mapMinimalContent(content) {
    return content.map((y) => {
      let mappedData = {
        fullData: y,
        url: (y.posters.length > 1) ? y.posters[1].converted_url : null,
        desc: '',
        run_time: y.run_time
      }
      return mappedData;
    });
  }

  mapCastMembers(content) {
    return content.map((x) => {
      let mappedData = {
        fullData: x,
        url: (!!x.icon_image_url) ? x.icon_image_url : '/assets/images/default-cast.png',
        desc: ''
      }
      return mappedData;
    })
  }

  mapCastSpecialActors(content) {
    return content.map(x => {
      let mappedData = {
        fullData: x,
        url: (!!x.icon_image_url) ? x.icon_image_url : '/assets/images/default-cast.png',
        desc: ''
      }
      return mappedData;
    })
  }

  mapCastDirectors(content) {
    return content.map(x => {
      if(x.role == "DIRECTOR") {
        let mappedData = {
          fullData: x,
          url: (!!x.icon_image_url) ? x.icon_image_url : '/assets/images/default-cast.png',
          desc: ''
        }
        return mappedData;
      }
    }).filter(x => x != undefined)
  }

  mapCastProducers(content) {
    return content.map(x => {
      if(x.role == "PRODUCER") {
        let mappedData = {
          fullData: x,
          url: (!!x.icon_image_url) ? x.icon_image_url : '/assets/images/default-cast.png',
          desc: ''
        }
        return mappedData;
      }
    }).filter(x => x != undefined)
  }

  mapEpisodes(content) {
    return content.map((y) => {
      let mappedData = this.mapEpisode(y);
      return mappedData;
    })
  }

  mapEpisode(y) {
    return {
      fullData: y,
      url: this.getPosterUrlByFiltering(y.posters, ["ONE_X", "TWO_X", "THREE_X", "FOUR_X"], "LANDSCAPE", true),
      run_time: this.getRunTime(y.run_time),
      release_date: y.release_date,
      cdn_url: y.cdn_url,
      name: y.name || y.description
    }
  }

  changeRouterLink(name: string) {
    name = name.toLowerCase().split(" ").join("-");
    return name;
  }

  addWatchedBorder(episode, watchedList) {
    return (watchedList.find(ele => ele.contentDetailId == episode.fullData.id)) ? true : false;
  }

  calculateWatchedTime(episode, watchedList) {
    var watchedEpisode = watchedList.find(ele => ele.contentDetailId == episode.fullData.id);
    let percentage = ((watchedEpisode.lastWatchedPosition / watchedEpisode.contentLength) * 100);
    return (percentage < 100) ? `${percentage.toFixed(2)}%` : "100%";
  }

  getPosterUrlByFiltering(posters, resolutionType, orientation_type, isImageWithTitle) {
    let imageTypes: string[] = (isImageWithTitle) ? ["IMAGE_WITH_TITLE"] : ["IMAGE_WITHOUT_TITLE"];
    if(posters.length != 0) {
      if (posters[0].hasOwnProperty("convertedUrl")) {
        console.log("asddsa")
      }
      if(posters.length >= 2){
        if (posters[0].convertedUrl !== undefined || posters[1].convertedUrl !== undefined) {
          let postersFilterByOrientation: any[] = posters.filter((poster) => (poster.orientationType == orientation_type));
          for (let orientation of postersFilterByOrientation) {
            for (let type of imageTypes) {
              let postersFilterByImageType: any[] = postersFilterByOrientation.filter((poster) => (poster.imageType == type));
              if (postersFilterByImageType.length != 0) {
                for (let resolution of resolutionType) {
                  let posterFilterByResolution = postersFilterByImageType.filter((poster) => (poster.resolutionType == resolution));
                  if (posterFilterByResolution.length != 0) {
                    if (posterFilterByResolution[0].convertedUrl == null) {
                      return posterFilterByResolution[1].convertedUrl ? posterFilterByResolution[1].convertedUrl
                        : "/assets/images/default/default_landscape.jpg";
                    }
                    return posterFilterByResolution[0].convertedUrl
                      ? posterFilterByResolution[0].convertedUrl
                      : "/assets/images/default/default_landscape.jpg";
                  }
                }
              }
            }
          }
        }
        let postersFilterByOrientation: any[] = posters.filter((poster) => (poster.orientation_type == orientation_type));
        for (let orientation of postersFilterByOrientation) {
          for (let type of imageTypes) {
            let postersFilterByImageType: any[] = postersFilterByOrientation.filter((poster) => (poster.image_type == type));
            if (postersFilterByImageType.length != 0) {
              for (let resolution of resolutionType) {
                let posterFilterByResolution = postersFilterByImageType.filter((poster) => (poster.resolution_type == resolution));
                if (posterFilterByResolution.length != 0) {
                  if (posterFilterByResolution[0].converted_url == null) {
                    return posterFilterByResolution[1].converted_url ? posterFilterByResolution[1].converted_url
                      : "/assets/images/default/default_landscape.jpg";
                  }
                  return posterFilterByResolution[0].converted_url
                    ? posterFilterByResolution[0].converted_url
                    : "/assets/images/default/default_landscape.jpg";
                }
              }
            }
          }
        }
      }
    }
    return (isImageWithTitle) ?
      ((orientation_type == "LANDSCAPE") ? "/assets/images/default/default_landscape.jpg" : "assets/images/default/default_portrait.jpg") :
      ((orientation_type == "LANDSCAPE") ? "/assets/images/default/default_landscape.jpg" : "/assets/images/default/default_portrait.jpg");
  }
}
