import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedRoutingModule } from './shared-routing.module';
import { CarouselComponent } from './carousel/carousel.component';
import { SliderComponent } from './slider/slider.component';
import { PosterComponent } from './poster/poster.component';
import { CarouselHoverDirective } from './directives/carousel-hover.directive';
import { SearchPosterHoverDirective } from './directives/search-poster-hover.directive';
import { AlertMessageComponent } from './alert-message/alert-message.component';
import { AlertActionComponent } from './alert-action/alert-action.component';
import { FooterComponent } from './footer/footer.component';
import { OtpComponent } from './otp/otp.component';
import { MaterialModule } from '../material/material.module';
import { DialogSubscribeComponent } from './dialog-subscribe/dialog-subscribe.component';
import { ScreenExceedWarningComponent } from './screen-exceed-warning/screen-exceed-warning.component';
import { UseAppBannerComponent } from './use-app-banner/use-app-banner.component';
import { ChangeAvatarComponent } from './change-avatar/change-avatar.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { NotificationsComponent } from './notifications/notifications.component';
import { NumbersonlyDirective } from './directives/numbersonly.directive';
import { CompleteStepActionComponent } from './complete-step-action/complete-step-action.component';
import { DownloadIosComponent } from './download-ios/download-ios.component';
import { LettersonlyDirective } from './directives/lettersOnly.directive';
import { HomeReportProblemComponent } from './home-report-problem/home-report-problem.component';
import { RequestEmailModalComponent } from './request-email-modal/request-email-modal.component';


@NgModule({
  declarations: [
    CarouselComponent, 
    SliderComponent, 
    PosterComponent, 
    CarouselHoverDirective, 
    SearchPosterHoverDirective, 
    AlertMessageComponent, 
    AlertActionComponent, 
    FooterComponent, 
    OtpComponent, 
    DialogSubscribeComponent, 
    ScreenExceedWarningComponent, 
    UseAppBannerComponent,
    ChangeAvatarComponent,
    NotificationsComponent,
    NumbersonlyDirective,
    CompleteStepActionComponent,
    DownloadIosComponent,
    LettersonlyDirective,
    HomeReportProblemComponent,
    RequestEmailModalComponent
  ],
  imports: [
    CommonModule,
    SharedRoutingModule,
    MaterialModule,
    NgxSpinnerModule
  ],
  exports: [
    CarouselComponent,
    SliderComponent,
    PosterComponent,
    CarouselHoverDirective,
    SearchPosterHoverDirective,
    FooterComponent,
    DialogSubscribeComponent,
    UseAppBannerComponent,
    ChangeAvatarComponent,
    NumbersonlyDirective,
    DownloadIosComponent,
    LettersonlyDirective

  ],
  entryComponents: [
    AlertMessageComponent,
    AlertActionComponent,
    OtpComponent,
    DialogSubscribeComponent,
    ScreenExceedWarningComponent,
    UseAppBannerComponent,
    ChangeAvatarComponent,
    NotificationsComponent,
    CompleteStepActionComponent,
    DownloadIosComponent,
    HomeReportProblemComponent,
    RequestEmailModalComponent
  ]
})
export class SharedModule { }
