import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from 'src/environments/environment';
import { HttpService } from './http/http.service';

@Injectable({
  providedIn: 'root'
})
export class ExternalService {

  constructor(private http: HttpService) { }

  getSocialMediaAuthUrl(socialMediaType: string): Observable<any> {
    return this.http.get<any>(`${environment.authService}${socialMediaType}/authorization-url`);
  }
  authenticateSocialMediaUser(authenticateCode: any, socialMediaType: string): Observable<any> {
    return this.http.post<any>(`${environment.authService}${socialMediaType}/authenticate`, { authorization_code: authenticateCode });
  }
}
