import { Directive, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appSearchPosterHover]'
})
export class SearchPosterHoverDirective {

  constructor() { }

  @Input() keyId: string = '';
  @Input() index: number = 0;
  @Input() postersPerRow: number = 0;

  @HostListener('mouseenter') onMouseEnter() {
    let id: string = `${this.keyId}hoverContainer${this.index}`;

    let element: any = document.getElementById(id);

    element.classList.remove("normal-transition-special");
    element.classList.add("show-hover-container-special");

    setTimeout(() => {
      element.classList.add("normal-transition-special");
    }, 500)
  }

  @HostListener('mouseleave') onMouseLeave() {
    let id = `${this.keyId}hoverContainer${this.index}`;
    let element: any = document.getElementById(id);

    element.classList.remove("normal-transition-special");
    element.classList.remove("show-hover-container-special");
  }
}
