import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/_core/services/authentication.service';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {

  today = new Date().toISOString();
  notifications = [];
  noresult: boolean = false;

  constructor(private authService: AuthenticationService) { }

  ngOnInit() {
    let currentUser = this.authService.currentUser()
    let username = currentUser.email || currentUser.mobile_number;
    this.authService.getNotifications(username).subscribe(res => {
      if(res.length > 0) {
        res.map(n => {
          if(n.dataKey == 'watch_enable') {
            n.dataValue = typeof n.dataValue == 'string' ? JSON.parse(n.dataValue) : n.dataValue;
            n.dataValue.forEach(ele => {
              if(ele.packageId == currentUser.package_id) {
                n['watchEnabledOn'] = ele.watchEnableDateAndTime;
                this.notifications.push(n)
              }
            });
          }
        });
      } else {
        this.noresult = true
      }
    })
  }

  navigateToContent(content) {
    
  }

}
