import { Injectable } from '@angular/core';
import { AuthenticationService } from '../authentication.service';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HttpResponse,
  HttpHeaders
} from '@angular/common/http';
import { Observable, BehaviorSubject, throwError } from 'rxjs';
import { catchError, filter, finalize, map, switchMap, take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CookieService } from '../cookie.service';
import { PopupService } from '../popup.service';

@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(
    private authenticationService: AuthenticationService,
    private popupService: PopupService
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    //set token
    const currentUser = this.authenticationService.currentUser();
    let isErrorHandled = (request.headers.has("is-error-handled"));

    request = (currentUser && !!currentUser.access_token) ? this.addToken(request, currentUser.access_token) : this.addXApiVersion(request);

    if (isErrorHandled) {
      let headers: HttpHeaders = request.headers.delete("is-error-handled", "true");
      request = request.clone({
        headers: headers
      })
    }

    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) return event;
      }),
      catchError(
        (err: HttpErrorResponse) => {
          if (!isErrorHandled) {
            this.handleErrorPopup(err)
          }
          return throwError(err);
        }),
      finalize(() => {

      }));
  }

  addToken(request: HttpRequest<any>, token: string) {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
        'X-Api-Version': environment.apiVersion
      }
    });
  }
  addXApiVersion(request: HttpRequest<any>) {
    return request.clone({
      setHeaders: {
        'X-Api-Version': environment.apiVersion
      }
    });
  }
  handleErrorPopup(error: HttpErrorResponse) {
    console.log(error.url);
    if (!error.url.includes("https://content-detail-api.dflix.com/tv-show/")) {
      let message = (!!error.error && !!error.error.error) ? error.error.error : "Please try again"
      this.popupService.openAlertMessageComponent(message, "warning", "We ran into a problem")
    }
  }

  handUnauthorizedError(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.authenticationService.isRefreshTokenProcessing) {
      this.authenticationService.isRefreshTokenProcessing = true;
      this.refreshTokenSubject.next(null);
      return this.authenticationService.refreshToken().pipe(
        switchMap((token: any) => {
          this.authenticationService.isRefreshTokenProcessing = false;
          this.refreshTokenSubject.next(token.token);
          return next.handle(this.addToken(request, token.token));
        })
      );
    } else {
      return this.refreshTokenSubject.pipe(
        filter(token => token !== null),
        take(1),
        switchMap(jwt => {
          return next.handle(this.addToken(request, jwt));
        })
      );
    }
  }
}
