import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from './authentication.service';
import { DeviceInfoService } from './device-info.service';
import { HttpService } from './http/http.service';

@Injectable({
  providedIn: 'root'
})
export class ScreensService {

  constructor(
    private http: HttpService,
    private deviceInfoService: DeviceInfoService,
    private authenticationService: AuthenticationService
  ) { }

  getScreens(screenId: number, size: number, page: number) {
    // TODO get country ID from cookie after login implementation
    let profileId: string = '';
    let profile = this.authenticationService.selectedProfile();
    if (!!profile) {
      profileId = `&profile-id=${profile.id}`;
    }
    if(profile) {
      return this.http.get(`${environment.screenServiceUrl}screens?content-size=10&country-id=1&limit=${size}${profileId}&page=${page}&device-type=${this.deviceInfoService.getDeviceType()}${this.appendScreenId(screenId)}`);
    } else {
      return this.http.get(`${environment.screenServiceUrl}screens?content-size=10&country-id=1&limit=${size}&page=${page}&device-type=${this.deviceInfoService.getDeviceType()}${this.appendScreenId(screenId)}`);
    }
  }
  getCategoryById(categoryId: number, page, size):Observable<any> {
    let profileId: string = '';
    let profile = this.authenticationService.selectedProfile();
    if (!!profile) {
      profileId = `&profile-id=${profile.id}`;
    }
    return this.http.get(`${environment.screenServiceUrl}categories/${categoryId}?limit=${size}${profileId}&page=${page}&device-type=${this.deviceInfoService.getDeviceType()}&country-id=1`);
  }

  private appendScreenId(screenId: number): string {
    return screenId != -1 ? '&screen-id=' + screenId : '';
  }
  getMenu(){
    return this.http.get(`${environment.screenServiceUrl}screens/menu-bar`);
  }
}
