import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from './authentication.service';
import { FirestoreService } from './firestore.service';

@Injectable({
  providedIn: 'root'
})
export class WatchListService {

  constructor(
    private fireStoreService: FirestoreService,
    private authenticationService: AuthenticationService,
    private router: Router
  ) { }

  addTowatchList(data: any) {
    if (!!this.authenticationService.currentUser()) {
      return this.fireStoreService.addToWatchList(data);
    } else {
      this.openLoginComponent();
    }
  }
  removeFromWatchList(id: number) {
    return this.fireStoreService.removeAddToWatchListContent(id);
  }
  findWatchContentById(id: number) {
    return this.fireStoreService.findWatchContentById(id);
  }
  openLoginComponent() {
    this.router.navigate(["/account/login"], {queryParams: {returnUrl: this.router.url}});
  }
}
