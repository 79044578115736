import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AuthenticationService } from 'src/app/_core/services/authentication.service';
import { PaymentService } from 'src/app/_core/services/payment.service';

@Component({
  selector: 'app-dialog-subscribe',
  templateUrl: './dialog-subscribe.component.html',
  styleUrls: ['./dialog-subscribe.component.scss']
})
export class DialogSubscribeComponent implements OnInit {

  number: string;
  isChange: boolean = false;
  message: string;
  isInvalidNumber: boolean = false;

  constructor(
    private authenticationService: AuthenticationService,
    public dialogRef: MatDialogRef<DialogSubscribeComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
  ) {
    this.message = data.message;
  }

  ngOnInit() {
    let number = this.data.number;
    let spliceValue = (number) ? number.split("").splice(2) : null;
    if (!!spliceValue && this.checkNumberValidation(spliceValue)) {
      this.number = spliceValue.join("");
    }
    this.dialogRef.updateSize("350px");
  }
  checkNumberValidation(spliceValue) {
    return (spliceValue[1] == "6" || spliceValue[1] == "7" || spliceValue[1] == "4") ? true : false;
  }
  submit() {
    while(this.number.charAt(0) === '0')
    {
      this.number = this.number.substring(1);
    }
    if (this.checkNumberValidation(this.number.toString().split(""))) {
      this.isChange = true
      this.dialogRef.close({ number: this.number, isChange: this.isChange });
    } else {
      this.isInvalidNumber = true;
    }
  }
}
