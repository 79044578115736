import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthenticationService } from 'src/app/_core/services/authentication.service';
import { DataService } from 'src/app/_core/services/data.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  profiles: any = [];

  constructor(
    private matDialogRef: MatDialogRef<ProfileComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private authenticationService: AuthenticationService,
    private dataService:DataService
  ) {
    this.profiles = data.profiles;
  }

  ngOnInit() {
  }
  closeModel(profile: any) {
    this.authenticationService.setProfileCookie(profile);
    this.matDialogRef.close({ res: profile });
    this.dataService.refreshLoginCredential(true);
  }
}
