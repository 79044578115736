import { Component, HostListener, OnInit } from '@angular/core';
import { MatDialog} from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AlertActionComponent } from 'src/app/shared/alert-action/alert-action.component';
import { AuthenticationService } from 'src/app/_core/services/authentication.service';
import { DataService } from 'src/app/_core/services/data.service';
import { PaymentService } from 'src/app/_core/services/payment.service';
import { PopupService } from 'src/app/_core/services/popup.service';

enum PackageType {MONTHLY, ANNUALY, NONE};
enum MainType {FREE, PREMIUM, NONE};

@Component({
  selector: 'app-package-select',
  templateUrl: './package-select.component.html',
  styleUrls: ['./package-select.component.scss']
})
export class PackageSelectComponent implements OnInit {

  packageId: any;
  returnUrl: string = '';
  userData: any;

  pkgSelected_p: boolean = false;
  pkgSelected_f: boolean = false;
  selectDisabled_p: boolean = false;
  selectDisabled_f: boolean = true;

  
  paymentHandler:any = null;
  checkbox_type = PackageType;
  currentChecked: PackageType;

  mainCheckType = MainType;
  mainCurrentChecked: MainType;
  // registered user
  user: any;
  routeData:any

  freeAmount: any;
  premiumAmount: any;
  annualAmount: any;
  agreedToContinue: boolean = false;

  constructor(
    private authenticationService: AuthenticationService,
    private paymentService: PaymentService,
    private popupService: PopupService,
    private router: Router,
    private route: ActivatedRoute,
    private matDialog: MatDialog,
    private dataService: DataService,
    private spinnerService: NgxSpinnerService
  ) {
  }

  @HostListener('window:popstate', ['$event'])
  onPopState(event) {
    this.dataService.passAccountStatus('')
  }

  ngOnInit() {
    this.user = this.authenticationService.currentUser();
    if(this.route.snapshot.queryParams.data){
      this.userData = JSON.parse(window.atob(this.route.snapshot.queryParams.data));
      if(this.userData.isNew) {
        this.mainCurrentChecked = MainType.FREE;
        this.packageId = 1
      }
      this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/home';
    }
    if(this.route.snapshot.queryParams.returnUrl) {
      this.returnUrl = this.route.snapshot.queryParams.returnUrl || '/home';
    }
    setTimeout(() => {
      this.spinnerService.show('pkg-select')
      this.dataService.passAccountStatus('COMPLETED');
      this.spinnerService.hide('pkg-select')
    }, 500);
    
    if (this.userData == null) {
      if (this.user.package_id == 1) {
        this.mainCurrentChecked = MainType.FREE;
        this.pkgSelected_f = true;
        this.pkgSelected_p = false;
      }
      if (this.user.package_id == 2) {
        this.mainCurrentChecked = MainType.PREMIUM
        this.currentChecked = PackageType.MONTHLY;
        this.pkgSelected_p = true;
        this.pkgSelected_f = false;
        this.selectDisabled_p = false
      }
      if (this.user.package_id == 4) {
        this.mainCurrentChecked = MainType.PREMIUM
        this.currentChecked = PackageType.ANNUALY;
        this.pkgSelected_p = true;
        this.pkgSelected_f = false;
        this.selectDisabled_p = false

      }
    }
    setTimeout(() => {
      this.authenticationService.getCountry().subscribe(res => {
        if(res) {
          this.getPackageDetails(res.iso_code)
        }
      })
    }, 200);
  }

  getPackageDetails(code) {
    this.paymentService.getPackageDetails().subscribe(res => {
      this.spinnerService.show('pkg-select');
      res.data.forEach(pkg => {
        if(pkg.package_type == "FREE") {
          this.freeAmount = {
            curr: code == 'LK' ? 'LKR' : '$',
            amount: code == 'LK' ? pkg.lkr : pkg.usd
          }
          this.spinnerService.hide('pkg-select');
        }
        if(pkg.package_type == "PREMIUM") {
          this.premiumAmount = {
            curr: code == 'LK' ? 'LKR' : '$',
            amount: code == 'LK' ? pkg.lkr : pkg.usd
          }
          this.spinnerService.hide('pkg-select');
        }
        if(pkg.package_type == "ANNUAL_PREMIUM") {
          this.annualAmount = {
            curr: code == 'LK' ? 'LKR' : '$',
            amount: code == 'LK' ? pkg.lkr : pkg.usd
          }
          this.spinnerService.hide('pkg-select');
        }
      });
    })
  }


  selectFromPackage(target) { 
    if(this.mainCurrentChecked === target) {
      this.mainCurrentChecked = MainType.NONE;
      return;
    }
    this.mainCurrentChecked = target;
    if(this.mainCurrentChecked == MainType.FREE) {
      this.packageId = 1;
      this.selectDisabled_f = false;
      this.selectDisabled_p = false;
      this.pkgSelected_f = true;
      this.pkgSelected_p = false;
      this.currentChecked = PackageType.NONE;
    }
    if(this.mainCurrentChecked == MainType.PREMIUM) {
      this.packageId = 2;
      this.selectDisabled_p = false;
      this.selectDisabled_f = true;
      this.pkgSelected_p = true;
      this.pkgSelected_f = false;
      this.currentChecked = PackageType.MONTHLY;
    }
  }

  selectFromPremium(target) {
    if(this.currentChecked === target) {
      this.currentChecked = PackageType.NONE;
      return;
    }
    this.currentChecked = target;
    if(this.currentChecked == PackageType.MONTHLY) {
      this.packageId = 2;
      this.mainCurrentChecked = MainType.PREMIUM
    }
    if(this.currentChecked == PackageType.ANNUALY) {
      this.packageId = 4;
      this.mainCurrentChecked = MainType.PREMIUM
    }
  }



  submit() {
    let message = '';

    if(this.packageId == 1) {
      message = "Do you wish to continue with the Free package?"
    } else if(this.packageId == 2) {
      message = "Do you wish to continue with the Premium Monthly package?"
    } else {
      message = "Do you wish to continue with the Premium Annual package?"
    }

    
    this.matDialog
    .open(AlertActionComponent, {
        width: '350px',
        disableClose: true, 
        data: {id: this.user != null ? this.user.user_id : this.userData.id, 
        message: message,
      }
    })
    .afterClosed().subscribe(res => {
      if(res) {
        let bodyForSubscription = {
          transactionType: "SUBSCRIPTION",
          transactionAmount: this.packageId == 2 ? this.premiumAmount : this.annualAmount,
          userId: this.user != null ? this.user.user_id : this.userData.id,
          packageId: this.packageId,
          returnUrl: this.returnUrl ? this.returnUrl : sessionStorage.getItem('payment-return-url'),
          currencyType: "LKR",
          fromRental: false
        };

        if(this.user != null && !this.route.snapshot.queryParams.data) {

          if (this.packageId != this.user.package_id) {
            if (this.packageId == 2) {
              let enc = window.btoa(JSON.stringify(bodyForSubscription));
              this.router.navigate(['payment', 'selection'], { queryParams: { token: enc } });
            } else if(this.packageId == 4) {
              let enc = window.btoa(JSON.stringify(bodyForSubscription));
              this.router.navigate(['payment', 'selection'], { queryParams: { token: enc } });
            } else {
              this.packageDowngrade(bodyForSubscription);
            }
          } else {
            this.dataService.isStepperAvailable(false)
            this.dataService.passCompleteStep(3)
            this.router.navigate(['/settings/account']);
          }
        } else {
          if (this.packageId != this.userData.packageId) {
            if (this.packageId == 2) {
              let enc = window.btoa(JSON.stringify(bodyForSubscription));
              this.router.navigate(['payment', 'selection'], { queryParams: { token: enc } });
            } else if(this.packageId == 4) {
              let enc = window.btoa(JSON.stringify(bodyForSubscription));
              this.router.navigate(['payment', 'selection'], { queryParams: { token: enc } });
            }else {
              this.packageDowngrade(bodyForSubscription);
            }
          } else {
            this.dataService.isStepperAvailable(false)
            this.dataService.passCompleteStep(3)
            // this.router.navigate(["/home"]);
            window.location.href = this.returnUrl;
          }
        }
      }
    })
    
  }
  packageDowngrade(paymentData) {
    delete paymentData["returnUrl"];
    paymentData["paymentMethod"] = "IPG";//This value is not neccessary property for donwgrade.But has to use not to break payment flow restriction in backend
    this.paymentService.subscribePayment(paymentData)
      .subscribe(
        (res) => {
          let user = this.authenticationService.currentUser();
          user.package_id = 1;
          user.package_name = "FREE";
          user.next_billing_date = res.next_billing_date
          this.authenticationService.setTokenCookie(user);
          this.popupService.openAlertMessageComponent("Your package downgraded to FREE", "success", "Package Downgrade")
          .subscribe((res) => {
            this.router.navigateByUrl(sessionStorage.getItem("payment-return-url"));
                sessionStorage.removeItem("payment-return-url");
          });
        },
        (error) => {
      });
  }

  checkValue(event) {
    this.agreedToContinue = event.target.checked
  }


}
