import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Router } from '@angular/router';

@Component({
  selector: 'app-complete-step-action',
  templateUrl: './complete-step-action.component.html',
  styleUrls: ['./complete-step-action.component.scss']
})
export class CompleteStepActionComponent implements OnInit {

  message: string;

  constructor(
    private dialogRef: MatDialogRef<CompleteStepActionComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private router: Router
  ) {
    this.message = data.message
   }

  ngOnInit() {
  }

  close() {
    this.dialogRef.close();
  }
  
  quit() {
    this.dialogRef.close()
    this.router.navigate(['/home']);
  }

}
