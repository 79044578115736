import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {CookieService} from './cookie.service';
import {ExternalService} from './external.service';
import {environment} from 'src/environments/environment';
import {map, tap} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {FirestoreService} from './firestore.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  isRefreshTokenProcessing = false;
  constructor(
    private cookieService: CookieService,
    private http: HttpClient,
    private externalService: ExternalService,
    private firestoreService: FirestoreService
  ) { }

  validateUser(credential: any): Observable<any> {
    return this.http.get<any>(`${environment.authService}users/validate/${credential}`, {headers: {'is-error-handled': 'true'}});
  }

  signIn(body: any): Observable<any> {
    return this.http.post<any>(`${environment.authService}users/sign-in?firebase=true`, body, { headers: { 'is-error-handled': 'true' } })
      .pipe(map((res) => {
        if(res.package_id == 1) {
          res.package_name = 'FREE'
        }
        if(res.package_id == 2) {
          res.package_name = 'PREMIUM'
        }
        if(res.package_id == 4) {
          res.package_name = 'PREMIUM ANNUAL'
        }
        return this.setTokenCookie(res)
    }));
  }

  getSocialMediaAuthUrl(type: string): Observable<any> {
    return this.externalService.getSocialMediaAuthUrl(type);
  }

  authenticateSocialMediaUse(authenticateCode: any, type: any): Observable<any> {
    return this.externalService.authenticateSocialMediaUser(authenticateCode, type);
  }

  getAllProfileByUserId(profileId: any): Observable<any> {
    return this.http.get<any>(`${environment.authService}users/${profileId}/profiles`);
  }

  createNewProfile(userId, profileId, body) {
    return this.http.post<any>(`${environment.authService}users/${userId}/profiles/${profileId}/new-profile`, body);
  }

  updateProfile(userId, profileId, body) {
    return this.http.put<any>(`${environment.authService}users/${userId}/profiles/${profileId}`, body);
  }

  deleteProfile(userId: number, profileId: number) {
    return this.http.delete<any>(`${environment.authService}users/${userId}/profiles/${profileId}`);
  }

  createNewOtp(body) {
    return this.http.post<any>(`${environment.authService}users/new-otp`, body, {headers: {'is-error-handled': 'true'}});
  }

  verifyNewOtp(body) {
    return this.http.post<any>(`${environment.authService}users/verify-otp`, body, {headers: {'is-error-handled': 'true'}});
  }

  verifyPasswordResetOtp(body) {
    return this.http.post<any>(`${environment.authService}users/password-reset/verify`, body, {headers: {'is-error-handled': 'true'}});
  }

  passwordResetOtp(body) {
    return this.http.post<any>(`${environment.authService}users/password-reset/otp`, body, {headers: {'is-error-handled': 'true'}});
  }

  changeEmailOtp(body) {
    return this.http.post<any>(`${environment.authService}users/change-email/otp`, body, {headers: {'is-error-handled': 'true'}});
  }

  changeMobileOtp(body) {
    return this.http.post<any>(`${environment.authService}users/change-mobile/otp`, body, {headers: {'is-error-handled': 'true'}});
  }

  verifyChangeEmailOtp(body) {
    return this.http.post<any>(`${environment.authService}users/change-email/verify`, body, {headers: {'is-error-handled': 'true'}});
  }

  verifyChangeMobileOtp(body) {
    return this.http.post<any>(`${environment.authService}users/change-mobile/verify`, body, {headers: {'is-error-handled': 'true'}});
  }

  completePasswordReset(body) {
    return this.http.post<any>(`${environment.authService}users/password-reset/complete`, body, {headers: {'is-error-handled': 'true'}});
  }

  registerUser(body, userId) {
    return this.http.post<any>(`${environment.authService}users/${userId}/register?firebase=true`, body, { headers: { 'is-error-handled': 'true' } }).pipe(map((res) => { return this.setTokenCookie(res) }));
  }

  changePackage(body, userId) {
    return this.http.put<any>(`${environment.authService}users/${userId}/change-packages`, body);
  }

  getAvatars() {
    return this.http.get<any>(`${environment.authService}profiles/avatars`);
  }
  requestEmail(body) {
    return this.http.post<any>(`${environment.authService}users/change-email/otp`, body, { headers: { 'is-error-handled': 'true' } });
  }
  verifyRequestEmail(body) {
    return this.http.post<any>(`${environment.authService}users/change-email/verify`, body, { headers: { 'is-error-handled': 'true' } });
  }

  verifyStatusComplete(userId) {
    return this.http.get<any>(`${environment.authService}users/package-select/${userId}`,  { headers: { 'is-error-handled': 'true' }});
  }
  public currentUser(): any {
    if (this.cookieService.getCookie(environment.cookies.currentUser)) {
      return JSON.parse(this.cookieService.getCookie(environment.cookies.currentUser));
    }
    return null;
  }

  public selectedProfile(): any {
    let selectedProfile = this.cookieService.getCookie(environment.cookies.selectedProfile);
    if (!!selectedProfile && selectedProfile != undefined && selectedProfile != 'undefined') {
      return JSON.parse(selectedProfile);
    }
    return null;
  }

  logout() {
    this.cookieService.deleteCookie(environment.cookies.currentUser);
    this.cookieService.deleteCookie(environment.cookies.selectedProfile);
    this.cookieService.deleteCookie(environment.cookies.cloudFrontSignature);
    this.cookieService.deleteCookie(environment.cookies.cloudFrontPolicy);
    this.cookieService.deleteCookie(environment.cookies.cloudFrontKeyPairId);
    localStorage.clear();
    // sessionStorage.clear();
    this.firestoreService.signOutFirebase();
  }

  setTokenCookie(res) {
    this.signIntoFirestore(res)
    this.cookieService.setCookie(environment.cookies.currentUser, JSON.stringify(res), null);
    return res;
  }

  signIntoFirestore(res) {
    if (!!res.firebase_token) {
      this.firestoreService.signUpWithFirebase(res.firebase_token);
    }
  }

  setProfileCookie(res) {
    this.cookieService.setCookie(environment.cookies.selectedProfile, JSON.stringify(res), null);
  }

  setSignedCookie() {
    this.cookieService.setCookieForSign(environment.cookies.cloudFrontKeyPairId, 'KYXZUVAL324F0', null);
    this.cookieService.setCookieForSign(environment.cookies.cloudFrontPolicy, 'eyJTdGF0ZW1lbnQiOlt7IlJlc291cmNlIjoiaHR0cHM6Ly8qLmRmbGl4LmNvbS8qIiwiQ29uZGl0aW9uIjp7IkRhdGVMZXNzVGhhbiI6eyJBV1M6RXBvY2hUaW1lIjoxNjU3MTg5Mzc3Mjc2fX19XX0_', null);
    this.cookieService.setCookieForSign(environment.cookies.cloudFrontSignature, 'rCY9E4BTq78SIjXICqR0ym8Ybs3bjQd0i7TdIrFzG~VDXcebVnJ6rKf256NkPK~WdSj21q1XN4JkGYrUbYXI45qoG-jv45hos4aXXbU14mlwXLe9nzdjbULaSmjOjYzPA1YvmEI92j1QX8updYPeS3cSq-Xu7ce2PMNXPU-SsfW2q~OMs1n-YCDkKqDzjcZywmjQLQd8vKQytCxAACThxEX9rq5WuAEgVyoA6bqofC0BYdGhWHZy8RQCHN130YudkgVSBlxyZRcAhlOE0MxRf6l31gZSbIFsnoTIiUUYnpTkC5AF0zqwNCcGjQMIzSxJIucQQpphLi1ACWbggVY9tQ', null);
  }

  getSignedCookies() {
    this.setSignedCookie();
    // return this.http.get<any>(`${environment.signedCookieServiceUrl}`).pipe(map((res) => { return this.setSignedCookie() }));;
  }

  refreshToken() {
    return this.http.get('').pipe(tap((user: any) => {
    }));
  }

  getUserName() {
    let currentUser = this.cookieService.getCookie(environment.cookies.currentUser);
    let selectedProfile = this.cookieService.getCookie(environment.cookies.selectedProfile);

    if (!!selectedProfile && selectedProfile != undefined && selectedProfile != 'undefined') {
      return JSON.parse(selectedProfile).name;
    }
    if (!!currentUser && currentUser != undefined && currentUser != 'undefined') {
      return JSON.parse(currentUser).name;
    }
    return 'Chief';
  }

  getOTPforRokuDevice(body) {
    return this.http.post<any>(`${environment.authService}roku/verify-code`, body);
  }
  getCountry() {
    return this.http.get<any>(`${environment.authService}my-country`, { headers: { 'is-error-handled': 'true' } });
  }

  getNotifications(username) {
    return this.http.get<any>(`${environment.authService}notifications?username=${username}&page=0&size=10`, { headers: { 'is-error-handled': 'true'} })
  }
}
