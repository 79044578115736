import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/_core/services/authentication.service';
import { DataService } from 'src/app/_core/services/data.service';
import { DeviceInfoService } from 'src/app/_core/services/device-info.service';
import { FirestoreService } from 'src/app/_core/services/firestore.service';
import { HelperService } from 'src/app/_core/services/helper.service';
import { WatchListService } from 'src/app/_core/services/watch-list.service';

@Component({
  selector: 'app-watch-list',
  templateUrl: './watch-list.component.html',
  styleUrls: ['./watch-list.component.scss']
})
export class WatchListComponent implements OnInit, OnDestroy {

  isPageLoaded = false;

  watchList: any[] = [];
  skeletons = [0, 0, 0, 0];

  gridColumns: string;
  mainPosterUrl: string = "assets/images/default/default_landscape.jpg";
  isApiRequested: boolean = false;
  list: any[] = [];
  profileChangeSubscription: any;

  isHover = true;
  isShowName = false;
  keyId = 'watch-list';
  borderRadius = '4px';

  movieList = []
  tvList =[]
  videoList = []
  liveList = []
  mappedWatchList = []

  constructor(
    private authenticationService: AuthenticationService,
    private fireStoreService: FirestoreService,
    private router: Router,
    private helperService: HelperService,
    private dataService: DataService,
    private watchListService: WatchListService,
    private deviceInfoService: DeviceInfoService
  ) {
  }

  ngOnDestroy(): void {
    if(this.profileChangeSubscription) {
      this.profileChangeSubscription.unsubscribe();
    }

    //when refresh the page redirect to the page not found
    // fixed not the best way
    if(this.watchList.length == 0 && this.isApiRequested) {
      this.router.navigate(['/my-list']);
      this.rerender();
    }
  }

  ngOnInit() {
    if(this.deviceInfoService.getDeviceType()=='MOBILE' || this.deviceInfoService.getDeviceType()=='TABLET') {
      this.isHover = false;
    }
    this.fireStoreService.setSnapshotToNull();
    this.watchList = [];
    this.refreshLoginCredential()
  }

  refreshLoginCredential() {
    this.profileChangeSubscription = this.dataService.initialLoginCredentail.subscribe(
      (res) => {
        this.watchList = [];
        if (res) {
          this.fireStoreService.setSnapshotToNull();
        }
        this.getWatchList();
      }
    );
  }

  refreshWatchList() {

    this.watchList = [];
    this.mappedWatchList = [];
    this.movieList = [];
    this.tvList = [];
    this.videoList = []
    this.liveList = []
    this.fireStoreService.getAddToWatchList(false)
      .then(
        (res) => {
          if (!!res) {
            let watchList = res.docs.map(
              (doc) => {
                let mappedData: any = {
                  fullData: doc.data(),
                  url: this.deviceInfoService.getDeviceType() == 'MOBILE'
                        ? this.helperService.getPosterUrlByFiltering(doc.data().posters, ["FOUR_X", "THREE_X", "TWO_X", "ONE_X"], "PORTRAIT", true)
                        : this.helperService.getPosterUrlByFiltering(doc.data().posters, ["FOUR_X", "THREE_X", "TWO_X", "ONE_X"], "LANDSCAPE", true),
                  name: doc.data().name,
                  id: doc.data().id,
                  genre: doc.data().genre.slice(0, 2).map(t => { return (t.name) ? t.name : t }),
                  run_time: doc.data().run_time,
                  desc: doc.data().description.split("").splice(0, 70).join("") + "...",
                  type: doc.data().type
                }
                return mappedData;
              }
            );

            watchList.map(w => {
              if(w.type == 'MOVIE') {
                this.movieList.push(w)
              }else if(w.type == 'TV_SHOW') {
                this.tvList.push(w)
              } else if(w.type == 'LIVE_CHANNEL') {
                this.liveList.push(w)
              } else {
                this.videoList.push(w)
              }
            })
            this.mappedWatchList.push(
              this.movieList.length> 0 ? {
                name: 'Movie',
                content: this.movieList
              } : [],
              this.tvList.length> 0 ? {
                name: 'Tv Show',
                content: this.tvList
              } : [],
              this.liveList.length> 0 ? {
                name: 'Live Channel',
                content: this.liveList
              } : [],
              this.videoList.length> 0 ? {
                name: 'Video Channel',
                content: this.videoList
              }: []
            )

            this.watchList = [...this.watchList, ...watchList]
            this.mainPosterUrl = (this.watchList.length > 0) ? this.watchList[0].url : "assets/images/default/default_landscape.jpg";
          }
        }
      );
  }

  getWatchList() {
    this.watchList = [];
    this.mappedWatchList = [];
    this.movieList = [];
    this.tvList = [];
    this.liveList = [];
    this.videoList = []
    if (!!this.authenticationService.currentUser()) {
      this.isApiRequested = true;
      this.fireStoreService.getAddToWatchList(true)
        .then(
          (res) => {
            this.isApiRequested = false;
            if (!!res) {
              let list = res.docs.map(
                (doc) => {
                  let data: any = doc.data();
                  return data;
                }
              );
              this.list = [...this.list, ...list];
              let watchList = res.docs.map(
                (doc) => {
                  let mappedData: any = {
                    fullData: doc.data(),
                    url: this.deviceInfoService.getDeviceType() == 'MOBILE'
                          ? doc.data().all_posters ? this.helperService.getPosterUrlByFiltering(doc.data().all_posters, ["FOUR_X", "THREE_X", "TWO_X", "ONE_X"], "PORTRAIT", true) : this.helperService.getPosterUrlByFiltering(doc.data().posters, ["FOUR_X", "THREE_X", "TWO_X", "ONE_X"], "PORTRAIT", true)
                          : doc.data().all_posters ? this.helperService.getPosterUrlByFiltering(doc.data().all_posters, ["FOUR_X", "THREE_X", "TWO_X", "ONE_X"], "LANDSCAPE", true) : this.helperService.getPosterUrlByFiltering(doc.data().posters, ["FOUR_X", "THREE_X", "TWO_X", "ONE_X"], "LANDSCAPE", true),
                    name: doc.data().name,
                    id: doc.data().id,
                    genre: doc.data().genre.slice(0, 2).map(t => { return (t.name) ? t.name : t }),
                    run_time: doc.data().run_time,
                    desc: doc.data().description.split("").splice(0, 70).join("") + "...",
                    type: doc.data().type
                  }
                  return mappedData;
                }
              );

              watchList.map(w => {
                if(w.type == 'MOVIE') {
                  this.movieList.push(w)
                }else if(w.type == 'TV_SHOW') {
                  this.tvList.push(w)
                }else if(w.type == 'LIVE_CHANNEL') {
                  this.liveList.push(w)
                } else {
                  this.videoList.push(w)
                }
              })
              this.mappedWatchList.push(
               this.movieList.length> 0 ? {
                  name: 'Movie',
                  content: this.movieList
                } : null,
                this.tvList.length> 0 ? {
                  name: 'Tv Show',
                  content: this.tvList
                } : null,
                this.liveList.length> 0 ? {
                  name: 'Live Channel',
                  content: this.liveList
                } : null,
                this.videoList.length> 0 ? {
                  name: 'Video Channel',
                  content: this.videoList
                }: null
              )
              this.mappedWatchList = this.mappedWatchList.filter(el => el != null)

              this.watchList = [...this.watchList, ...watchList]
              this.mainPosterUrl = (this.watchList.length > 0) ? this.watchList[0].url : "assets/images/default/default_landscape.jpg";
              this.dataService.passAddWatchListData(this.list);
              this.isPageLoaded = true;
            }
          }
        );
    }
  }

  selectContent(content: any) {
    this.router.navigate(
      ['other',
        this.helperService.changeRouterLink(content.data.type),
        content.data.slug
      ]);
  }

  navigateToDetailView() {
    this.router.navigate(['other', this.helperService.changeRouterLink(this.watchList[0].fullData.type), this.watchList[0].fullData.slug]);
    var element: any = document.getElementById("nav-bar");
    element.classList.remove("nav-bar-color");
    element.classList.add('nav-bar-color-scroll');
  }
  removeFromWatchList() {
    this.watchListService.removeFromWatchList(this.watchList[0].fullData.id)
      .then(
        (res) => {
          this.refreshWatchList();
        }
      );
  }

  rerender() {
    this.getWatchList()
  }

  getPosterWidth() {
    if(this.deviceInfoService.getDeviceType() == 'MOBILE') {
      return 100;
    } else {
      return 300;
    }
  }

}
