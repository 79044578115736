import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AuthenticationService } from 'src/app/_core/services/authentication.service';
import { DataService } from 'src/app/_core/services/data.service';
import { PaymentService } from 'src/app/_core/services/payment.service';
import {DeviceInfoService} from '../../_core/services/device-info.service';

@Component({
  selector: 'app-dialog-otp',
  templateUrl: './dialog-otp.component.html',
  styleUrls: ['./dialog-otp.component.scss']
})
export class DialogOtpComponent implements OnInit {

  number: string;
  errorMessage: string;
  time: number = 60;
  otp: number[] = [null, null, null, null, null, null];
  isArrowKey: boolean = false;
  setInterval: any;
  isResend = false;
  isMobileView = false;


  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    public dialogRef: MatDialogRef<DialogOtpComponent>,
    private authenticationService: AuthenticationService,
    private paymentService: PaymentService,
    private dataService: DataService,
    private deviceInfoService: DeviceInfoService,

  ) { }

  ngOnInit() {
    if(this.deviceInfoService.getDeviceType() == "MOBILE") {
      this.isMobileView = true;
    }
    this.dialogRef.updateSize('500px');
    this.number = this.data.number;
    // console.log(this.number)
    this.countdown()
  }

  countdown() {
    const temp = this;
    temp.time = 60;
    this.setInterval = setInterval(function () {
      if (temp.time == 0) {
        clearInterval(temp.setInterval);
      }
      temp.time -= 1;
      var res = document.getElementById('resend')
      var otp = document.getElementById('otpdiv')
      var time = document.getElementById('time')
      if(temp.time <= 0) {
        res ? res.style.display = 'block' : null;
        otp ? otp.style.display = 'none' : null;
      } else {
        res ? res.style.display = 'none' : null
        otp ? otp.style.display = 'block' : null;
        time ? time.innerHTML = temp.time.toString() : null;
      }
    }, 1000)
    this.dataService.intervalId = this.setInterval;
  }

  validateKeyUp(nextId, event, prevId, nextIndex) {
    const code = event.keyCode | event.which;
    var nextElement;
    var previousElement;
    if (nextId) {
      nextElement = document.getElementById(nextId);
    }
    if (prevId) {
      previousElement = document.getElementById(prevId);
    }

    switch (true) {
      case (code == 8)://back space code
        break;
      case (code == 37)://left code
        setTimeout(() => { previousElement.focus(); }, 0);
        this.isArrowKey = true;
        break;
      case (code == 39)://right code
        setTimeout(() => { nextElement.focus(); }, 0);
        this.isArrowKey = true;
        break;
      case ((96 <= code && code <= 105) || (48 <= code && code <= 57)): //number code
        if (nextElement && event.target.value) {
          setTimeout(() => { nextElement.focus(); }, 0);
        }
        if (!this.isArrowKey) {
          this.otp[nextIndex] = null;
        }
      default:
        this.validateOTP();
        break;
    }
  }
  validateOTP() {
    if ((!!this.otp[0] || this.otp[0] == 0) && (!!this.otp[1] || this.otp[1] == 0) &&
    (!!this.otp[2] || this.otp[2] == 0) && (!!this.otp[3] || this.otp[3] == 0) &&
    (!!this.otp[4] || this.otp[4] == 0) && (!!this.otp[5] || this.otp[5] == 0)) {
      let otp = this.otp.join("")
      this.dialogRef.close(otp);
      clearInterval(this.setInterval)
    }
  }

  resend() {
    let data = {
      app_user_id: this.authenticationService.currentUser().user_id,
      msisdn: `${this.number}`
    }
    this.paymentService.subscribeToDialog(data).subscribe(res => {
      if(res) {
        this.isResend = true;
        this.countdown()
      }
    }, (err) => {
      clearInterval(this.setInterval)
    })
  }

  close() {
    this.dialogRef.close();
    clearInterval(this.setInterval);
  }

}
