// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  cookies: {
    currentUser: 'currentUser',
    selectedProfile: 'selectedProfile',
    cloudFrontSignature: 'CloudFront-Signature',
    cloudFrontKeyPairId: 'CloudFront-Key-Pair-Id',
    cloudFrontPolicy: 'CloudFront-Policy'
  },
  videoFormats: {
    dash: 'dash',
    hls: 'hls'
  },
  licenseServers: {
    dash: 'com.widevine.alpha',
    hls: ''
  },
  drmConfigurations: {
    widevineLicenseServer: 'https://widevine-dash.ezdrm.com/widevine-php/widevine-foreignkey.php?pX=41FD61&CustomData=',
    fairplayLicenseServer: 'https://fps.ezdrm.com/api/licenses/B4EE50F9-AB3B-422F-823A-9FD835D05793&CustomData=',
    fairplayCertificateUrl: 'https://gluuoo-2-0-destination-1kzpe6ig2ciov.s3.amazonaws.com/fairplay.cer',
  },

  // screenServiceUrl: 'https://screen.dev.dflix.rumex.com.lk/',
  // contentDetailServiceUrl: 'https://content.dev.dflix.rumex.com.lk/',
  // authService: 'https://auth.dev.dflix.rumex.com.lk/',
  // elasticSearchUrl: 'https://search.dev.dflix.rumex.com.lk/',
  // paymentServiceUrl: 'https://payment.dev.dflix.rumex.com.lk/',
  // addServiceUrl: 'https://dev.ads.api.rumex.com.lk/',
  // signedCookieServiceUrl: 'http://ec2-65-0-169-219.ap-south-1.compute.amazonaws.com:3000/getSignedCookie',
  // notificationServiceUrl: 'http://54.86.124.10:8086/',
  // contentUrl: 'https://content.api.dflix.rumex.com.lk/',


  //prod env for test
  screenServiceUrl: "https://screen-api.dflix.com/",
  contentDetailServiceUrl: 'https://content-detail-api.dflix.com/',
  // authService: 'http://54.255.173.193:9083/',
  authService: 'https://auth-api.dflix.com/',
  // authService: 'http://localhost:9083/',
  elasticSearchUrl: 'https://search-api.dflix.com/',
  paymentServiceUrl: 'https://payment-api.dflix.com/',
  signedCookieServiceUrl: 'http://ec2-65-0-169-219.ap-south-1.compute.amazonaws.com:3000/getSignedCookie',
  notificationServiceUrl: 'http://54.86.124.10:8086/',
  addServiceUrl: 'https://ads-api.dflix.com/',
  contentUrl: 'https://content-detail-api.dflix.com/',

  testElasticSearchUrl: 'https://search-dflix-contents-public-wadyrbwwg3ggw7gfjstom4dgga.ap-south-1.es.amazonaws.com/contents/_search',


  recaptcha: {
    siteKey: '6Lf7MSkdAAAAAPUYh3s4eQdJsIKgGHNEzuGFaxCz',
  },

  stripeKey: 'pk_live_qU7jec7E0FLb0OHtAozsSLCQ',

  ipgKey: 'ca4cdbbdc39347979c87dc01b323a457',

  deviceTypes: {
    mobile: 'MOBILE',
    tablet: 'TABLET',
    desktop: 'TV'
  },
  apiVersion: 'v1',
  screenOrientation: {
    types: {
      portrait: 'portrait-primary',
      landscape: 'landscape-primary'
    },
    portrait: 'PORTRAIT',
    landscape: 'LANDSCAPE'
  },
  categoryTemplates: {
    standard: 'STANDARD',
    exclusive: 'EXCLUSIVE'
  },
  page: 0,
  size: 10,
  contentTypes: {
    movie: 'MOVIE',
    tvShow: 'TV_SHOW',
    tvEpisode: 'TV_EPISODE',
    liveChannel: "LIVE_CHANNEL",
    videoChannel: "VIDEO_CHANNEL",
    video: "VIDEO"
  },
  contentTypesForRq: {
    movie: 'movies',
    tvShow: 'tv-shows',
    tvEpisode: 'tv-episodes',
    videoChannel: 'video-channels',
    liveChannel: 'live-channels',
    video: "videos"
  },
  loginTypeOtp: {
    mobile: 'mobile',
    email: "email"
  },
  loginTypeSignIn: {
    mobile: "MOBILE",
    email: "EMAIL"
  },
  firebaseConfig: {
    apiKey: "AIzaSyDSRPgy80kCIWgcJChlzBBrKvDWPi-Tbfc",
    authDomain: "derana-reality-309809.firebaseapp.com",
    databaseURL: "https://derana-reality-309809-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "derana-reality-309809",
    storageBucket: "derana-reality-309809.appspot.com",
    messagingSenderId: "1056114962062",
    appId: "1:1056114962062:web:babb9c5dfc4c7ade6ea3df",
    measurementId: "G-P9XJSW1T1Z"
  },
  fireBaseProfile: 'profileProduction',
  fireBaseScreen: 'screensProduction',
  fireBaseWatchHours: 'watchHoursProduction',
  // fireBaseAdvertisement: 'advertisementDev',
  premiumScreens: 2,
  freeScreens: 1,
  cuePoints: {
    openingCredit: "OPENING_CREDITS",
    endCredit: "END_CREDITS",
    advertisement: "ADVERTISEMENT",
    midBreak: "MID_BREAK",
    nextEpisodePreview: "NEXT_EPISODE_PREVIEW"
  },
  watchHourTimer: 5, //in seconds

  // firebase path changes
  firebaseNewProfile: 'production'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

