import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { ContentData } from 'src/app/_core/model/Content';
import { FirestoreService } from 'src/app/_core/services/firestore.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss']
})
export class CarouselComponent implements OnInit {

  constructor(private firestoreService: FirestoreService) { }

  @Input() value: ContentData[];
  @Input() keyId = "";
  @Output() catClick = new EventEmitter();
  @Output() getWatchList = new EventEmitter();
  @Input() imgWidth: number = 300;
  @Input() gap: number = 3;
  @Input() isHover: boolean = true;
  @Input() isShowName: boolean = false;
  @Input() borderRadius: string = '4px';
  @Input() isNormalTransition: boolean = true;
  @Input() isContinueWatching: boolean = false;
  @Input() isSearch: boolean = true;
  @Output() scrollAtLast = new EventEmitter();
  @Input() isApiRequested: boolean = false;
  @Input() isHasNextPage: boolean = true;

  scrollTo: number;


  ngOnInit(): void {
    setTimeout(() => {
      this.showArrows();
    }, 0);
  }
  move(isNext, arrowType) {
    if (document.getElementById(`${this.keyId}${arrowType}`).style.opacity != '0' || window.innerWidth < 500) {
      let element = document.getElementById(`${this.keyId}carousel-container`);
      if (isNext && element.clientWidth + element.scrollLeft == element.scrollWidth) {//scroll to beginning position when at last
        this.scrollToBegin();
      } else if (!isNext && element.scrollLeft == 0 && !this.isHasNextPage) {//scroll to last after all data retrieve
        this.scrollToLast();
      } else {
        this.scrollTo = (isNext) ? element.scrollLeft + element.clientWidth - this.imgWidth * 2 : element.scrollLeft - element.clientWidth + this.imgWidth * 2;
        element.scrollTo({
          left: this.scrollTo,
          behavior: 'smooth'
        });
      }
    }
  }
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.showArrows();
  }
  passData(category: any) {
    this.catClick.emit(category);
  }
  sendGetWatchListRequest() {
    this.getWatchList.emit();
  }
  getMovieRunTime(runTime: number) {
    return Math.floor(runTime / 60) + 'hr ' + runTime % 60 + 'min';
  }
  showArrows() {
    let element = document.getElementById(`${this.keyId}carousel-container`);
    let preArrowElement = document.getElementById(`${this.keyId}prev`);
    let nextArrowElement = document.getElementById(`${this.keyId}next`);

    preArrowElement ? preArrowElement.style.display = 'none' : null;

    if(element) {
      if (element.clientWidth == element.scrollWidth) {
        nextArrowElement ? nextArrowElement.style.display = 'none' : null;
      } else {
        nextArrowElement ? nextArrowElement.style.display = 'grid' : null;
      }
    }

    element ? element.addEventListener("scroll", (event) => {
      if (((element.scrollWidth - element.clientWidth - this.imgWidth) < element.scrollLeft) && !this.isApiRequested && this.isHasNextPage) {
        this.scrollAtLast.emit();
      }
      if(element.scrollLeft == 0) {
        // this.scrollAtLast.emit(yes);
      }

      if (!!preArrowElement) {
        preArrowElement.style.display = ((element.scrollLeft == 0 && !this.isHasNextPage) || (element.scrollWidth > element.clientWidth)) ? 'grid' : 'none';
      }
    }) : null;
  }
  scrollToBegin() {
    let element = document.getElementById(`${this.keyId}carousel-container`);
    let length = (this.value.length < environment.size || this.value.length == environment.size) ? this.value.length : environment.size;
    let firstcontent = this.value.slice(0, length);

    this.value = [...this.value, ...firstcontent];
    setTimeout(() => {
      this.scrollTo = element.scrollLeft + element.clientWidth;
      element.scrollTo({
        left: this.scrollTo,
        behavior: 'smooth'
      });
    }, 0);
    setTimeout(() => {
      this.scrollTo = 0;
      element.scrollTo({
        left: this.scrollTo
      });
      this.value.splice(this.value.length - length, length);
    }, 1000);
  }
  scrollToLast() {
    let element = document.getElementById(`${this.keyId}carousel-container`);
    let length = (this.value.length < environment.size || this.value.length == environment.size) ? this.value.length : environment.size;
    let lastcontent = this.value.slice(this.value.length - length, this.value.length);
    let previousWidth = element.scrollWidth;

    this.value = [...lastcontent, ...this.value];
    setTimeout(() => {
      this.scrollTo = element.scrollWidth - previousWidth;
      element.scrollTo({
        left: this.scrollTo
      });
      this.scrollTo = element.scrollLeft - element.clientWidth;
      element.scrollTo({
        left: this.scrollTo,
        behavior: 'smooth'
      });
    }, 0);
    setTimeout(() => {
      this.scrollTo = element.scrollWidth - element.clientWidth
      element.scrollTo({
        left: this.scrollTo
      });
      this.value.splice(0, length);
    }, 1000);
  }
}
