import { Injectable } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DeviceInfoService {

  constructor(private deviceInfoService: DeviceDetectorService) { }

  /**
   * Getting the device type
   */
  getDeviceType(): string {
    return this.deviceInfoService.isDesktop() ? environment.deviceTypes.desktop :
      this.deviceInfoService.isTablet() ? environment.deviceTypes.tablet : environment.deviceTypes.mobile;
  }

  /**
   * Get device Orientation
   */
  getDeviceOrientation(): string {
    return window.screen.orientation.type === environment.screenOrientation.types.portrait ? environment.screenOrientation.portrait
      : environment.screenOrientation.landscape;
  }
}
