import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Platform } from '@angular/cdk/platform';

@Component({
  selector: 'app-use-app-banner',
  templateUrl: './use-app-banner.component.html',
  styleUrls: ['./use-app-banner.component.scss']
})
export class UseAppBannerComponent implements OnInit {

  constructor(private matDialog: MatDialog, private platform: Platform,) { }

  ngOnInit() {
    if(this.platform.ANDROID) {
      // window.location.href = 'https://play.google.com/store/apps/details?id=lk.rumex.deranareality';
    }
  }

  closeBanner() {
    this.matDialog.closeAll();
    sessionStorage.setItem('skipped', 'true');
  }

  downloadApp() {
    if(this.platform.IOS) {
      window.open('https://apps.apple.com/lk/app/dflix/id1560605917', '_blank')
    }
    if(this.platform.ANDROID) {
      window.open('https://play.google.com/store/apps/details?id=lk.rumex.deranareality', '_blank')
    }
  }

}
