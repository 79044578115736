import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { BehaviorSubject } from 'rxjs';
import { ScreenExceedWarningComponent } from 'src/app/shared/screen-exceed-warning/screen-exceed-warning.component';
import { environment } from 'src/environments/environment';
import { Profile } from '../model/Account';
import { AuthenticationService } from './authentication.service';
import { ContentService } from './content.service';
import { CookieService } from './cookie.service';
import { FirestoreService } from './firestore.service';
import { PopupService } from './popup.service';
import { Platform } from '@angular/cdk/platform';

import * as firebase from 'firebase/app';

@Injectable({
  providedIn: 'root'
})
export class PlayerService {

  returnUrl: string = "/";

  public playNextVideoSource = new BehaviorSubject(null);
  initialVideoCall = this.playNextVideoSource.asObservable();

  constructor(
    private platform: Platform,
    private router: Router,
    private authenticationService: AuthenticationService,
    private matDialog: MatDialog,
    private cookieService: CookieService,
    private popupService: PopupService,
    private contentService: ContentService,
    private firestoreService: FirestoreService,
    private spinnerService: NgxSpinnerService,
  ) {
  }

  navigateToPlayer(content: any, videoDetails, customizedData, slug, isNextVideo?: boolean, isCatchUp?: boolean) {
    if (this.checkLogin() || content.type == 'trailer') {
      if (!!videoDetails.manifestUri && videoDetails.manifestUri != "") {
        let encVideoDetails = window.btoa(unescape(encodeURIComponent(JSON.stringify(videoDetails))));
        let encCustomizedData = window.btoa(unescape(encodeURIComponent(JSON.stringify(customizedData))));
        let mapContent = {
          id: content.id,
          type: content.type,
          run_time: content.run_time,
          genre: content.genres ? content.genres.map(g => g['name']) : null,
          posters: content.posters,
          all_posters: content.all_posters,
          title: content.title,
          slug: slug,
          nextVideo: (content.next_episodes && content.next_episodes.data && content.next_episodes.data.length > 0) ? content.next_episodes.data[0] :
            ((content.next_videos && content.next_videos.data && content.next_videos.data.length > 0) ? content.next_videos.data[0] : null)
        }
        let encContent = window.btoa(unescape(encodeURIComponent(JSON.stringify(mapContent))));

        this.router.navigate(["player", content.type.toLowerCase()], { queryParams: { vdToken: encVideoDetails, cusToken: encCustomizedData, contToken: encContent } });

        if (isNextVideo) {
          this.playNextVideoSource.next({ vdToken: encVideoDetails, cusToken: encCustomizedData, contToken: encContent });
        }
        if (isCatchUp) {
          this.playNextVideoSource.next({ vdToken: encVideoDetails, cusToken: encCustomizedData, contToken: encContent });
        }
      } else {
        this.popupService.openAlertMessageComponent("It's not you. It's us. Please make a report.", "info", "Could not play content")
      }
    } else {
      this.openLoginComponent();
    }
  }
  playVideo(contentType, slug, videoDetails, isTrailer, isNextVideo?: boolean, inRelated?: boolean, vid?) {
    if (!isTrailer) {
      if (this.checkLogin()) {
        this.getVideoDetail(contentType, slug, videoDetails, isNextVideo, inRelated, vid);
      } else {
        this.openLoginComponent();
      }
    } else {
      let customizedData = {
        caption: "Trailer"
      };
      this.navigateToPlayer({ type: "Movie" }, videoDetails, customizedData, null, false);
    }
  }
  getVideoDetail(contentTypeForRq: string, slug: string, videoDetails, isNextVideo: boolean, inRelated?: boolean, vid?) {
    let currentUser = this.authenticationService.currentUser();
    if(currentUser.is_premium_user == true && currentUser.package_name == 'FREE'){
      currentUser.package_name = 'PREMIUM';
    }
    console.log("asd")
    this.contentService.getContentBySlug(slug, this.getContentTypeForRq(contentTypeForRq))
      .subscribe(
        (res) => {
          let isCdnAvailable = (!!res.cdn_url && res.cdn_url != "") ? true : false;//because of this logic it does not need to check cdn_url==ER-001
          let isFreeToWatch = this.isFreeToWatch(currentUser.package_name, res);
          let isHasToPurchase = (res.monetization.free_to_watch.length == 0) ? true : false;
          let isPlanAvailable = this.isPlanAvailable(currentUser.package_name, res);
          if ((isCdnAvailable) && (isFreeToWatch || isHasToPurchase || isPlanAvailable)) {
            this.checkScreenCount(res, videoDetails, slug, isNextVideo, contentTypeForRq, inRelated, vid);
          } else if (isFreeToWatch && !isCdnAvailable && !isHasToPurchase) {
            if(res.type == 'TV_SHOW') {
              this.router.navigate(['other', this.getContentTypeForRq(res.type), slug])
            } else if(res.type=='VIDEO_CHANNEL') {
              this.router.navigate(['other', 'video_channel', slug])
            } else {
              this.spinnerService.hide('player');
              this.popupService.openAlertMessageComponent("It's not you. It's us. Please make a report.", "info", "Could not play content")
            }
          } else if (isHasToPurchase) {
            this.spinnerService.hide('player');
            this.popupService.openAlertMessageComponent("You may need to purchase the content", "info", "Purchase Content")
          } else if (!isFreeToWatch) {
            this.spinnerService.hide('player');
            this.popupService.openAlertMessageComponent("To continue to watch you have to \nupgrade the package", "info", "Upgrade Package")
              .subscribe(
                (res) => {
                  this.openPackageUpgradeComponent();
                }
              );
          } else {
            this.spinnerService.hide('player');
            this.popupService.openAlertMessageComponent("It's not you. It's us. Please make a report.", "info", "Could not play content")
          }

        }, (err) => {
          // console.log(err)
          // this.router.navigate(['other',contentTypeForRq, slug])
        }
      );
  }
  setPlayerContent(res, videoDetails, slug, isNextVideo, contentTypeForRq, inRelated, vid) {
    let customizedData = {
      cuePoints: res.cue_points,
      caption: (!!res.title && !!res.title.english) ? res.title.english : ""
    };
    customizedData['isMovie'] = inRelated ? true : null
    customizedData['isLiveChannel'] = inRelated ? false : null

    videoDetails["manifestUri"] = res.cdn_url;
    videoDetails["dash_manifest"] = res.cdn_dash_url;

    if (!!res.vtt_url) {
      let splitVtt = res.vtt_url.split("/");
      videoDetails["vtt_url"] = splitVtt.splice(2, splitVtt.length).join("/");
    } else {
      videoDetails["vtt_url"] = null;
    }
    videoDetails["keywords"] = (res.keywords) ? res.keywords : null;
    videoDetails["subtitles"] = res.subtitles;
    videoDetails["drm_customData"] = this.getCookieString("currentUser", contentTypeForRq, slug);

    let endCuePoint = (!!res.cue_points) ? res.cue_points.find(ele => ele.type == "END_CREDITS") : null;
    let endCueTime = (!!endCuePoint) ? `&${endCuePoint.cue_out}` : "";

    let currentUser = this.authenticationService.currentUser();
    let currentProfile = this.authenticationService.selectedProfile();

    let userId = currentUser.user_id
    let age = (currentProfile.age) ? `&age=${currentProfile.age}` : ""
    let gender = (currentProfile.gender_type) ? `&gender=${currentProfile.gender_type}` : "";

    if(this.platform.SAFARI || this.platform.IOS || currentUser.package_id == 2 || currentUser.package_id == 4) {
      videoDetails["tagUrl"] = '';
    } else {
      if(vid) {
        videoDetails["tagUrl"] = `${environment.addServiceUrl}serveAds?contentId=${vid}&contentDuration=${res.run_time}&userId=${userId}${age}${gender}${endCueTime}`;
      } else {
        videoDetails["tagUrl"] = `${environment.addServiceUrl}serveAds?contentId=${res.id}&contentDuration=${res.run_time}&userId=${userId}${age}${gender}${endCueTime}`;
      }
    }
    this.navigateToPlayer(res, videoDetails, customizedData, slug, isNextVideo);
  }
  public getCookieString(name: any, contentTypeForRq, slug) {
    let currentUser = JSON.parse(this.cookieService.getCookie(name));
    currentUser["detailUrl"] = `${environment.contentDetailServiceUrl}${this.getContentTypeForRq(contentTypeForRq)}/${slug}?country-id=1&device-type=TV`;
    return JSON.stringify(currentUser).replace("}", '').replace("{", '');
  }
  playSelectedVideo(content, videoDetails, customizedData, slug) {
    let mapContent = {
      id: content.id,
      type: content.type,
      run_time: content.run_time,
      posters: content.posters,
      slug: slug
    }
    let data = {
      content: mapContent,
      videoDetails: videoDetails,
      customizedData: customizedData,
    }

  }
  getContentTypeForRq(contentType) {
    switch (contentType) {
      case environment.contentTypes.movie:
      case environment.contentTypesForRq.movie:
        return environment.contentTypesForRq.movie;
      case environment.contentTypesForRq.tvShow:
        return environment.contentTypesForRq.tvShow
      case environment.contentTypes.tvShow:
        return 'tv_show'
      case environment.contentTypesForRq.tvEpisode:
      case environment.contentTypes.tvEpisode:
        return environment.contentTypesForRq.tvEpisode;
      case environment.contentTypesForRq.liveChannel:
      case environment.contentTypes.liveChannel:
        return environment.contentTypesForRq.liveChannel;
      case environment.contentTypesForRq.videoChannel:
      case environment.contentTypes.videoChannel:
        return environment.contentTypesForRq.videoChannel;
      case environment.contentTypes.video:
        return environment.contentTypesForRq.video;
      case 'tv_episodes':
        return 'tv-episodes';
      default:
        break;
    }
  }
  isFreeToWatch(packageName, content) {
    if(packageName == 'PREMIUM ANNUAL') {
      packageName = 'ANNUAL_PREMIUM'
    }
    let isFreeToWatch = content.monetization.free_to_watch.find(ele => ele == packageName);
    return (isFreeToWatch) ? true : false;
  }
  isPlanAvailable(packageName, content) {
    if(packageName == 'PREMIUM ANNUAL') {
      packageName = 'ANNUAL_PREMIUM'
    }
    let isPlanAvailable = content.monetization.plans.find(ele => ele.package_type == packageName);
    return (isPlanAvailable) ? true : false;
  }

  stopPlayer() {

  }

  checkLogin() {
    return (!!this.authenticationService.currentUser()) ? true : false;
  }

  openLoginComponent() {
    // login change
    this.router.navigate(["/account/login"], {queryParams: {returnUrl: this.router.url}});
  }
  isValidMatureRating(content) {
    let profile: Profile = JSON.parse(this.cookieService.getCookie("selectedProfile"));
    switch (profile.maturity_rating) {
      case "KID":
        return (content.maturity_rating == "KID") ? true : false;
      case "STANDARD":
        return (content.maturity_rating != "MATURE") ? true : false;
      case "MATURE":
        return true;
      default:
        false;
      //   return (isLiveChannel) ? true : false;
    }
  }
  openPackageUpgradeComponent() {
    this.router.navigate(['/account/package-select'],
      {
        queryParams: {
          data: null,
        }
      }
    );
  }
  saveToFirebase(content, isMovie, time, tv_show_slug?: string, tv_show_id?: any, type?: string, seasonId?: any, episodeNumber?:any) {
    var firebaseMappedData: any = {};
    firebaseMappedData["lastWatchedPosition"] = parseInt((time * 1000).toFixed(0));
    firebaseMappedData["timestamp"] = new Date().toISOString();
    firebaseMappedData["contentDetailSlug"] = content.slug.split("?")[0];
    firebaseMappedData["contentLength"] = content.run_time * 60000;
    firebaseMappedData["contentDetailId"] = content.id;
    // firebaseMappedData["posterUrls"] = this.mapPosterUrls(content.posters);
    firebaseMappedData["posterUrls"] = this.mapPosterUrls(content.all_posters);
    firebaseMappedData["title"] = content.title;
    firebaseMappedData["genre"] = content.genre;



    // new fields to firebase
    firebaseMappedData['age'] = this.authenticationService.selectedProfile().age
    firebaseMappedData['gender'] = this.authenticationService.selectedProfile().gender_type
    firebaseMappedData['country'] = this.authenticationService.currentUser().country
    firebaseMappedData['packageType'] = this.authenticationService.currentUser().package_name


    if (isMovie) {
      firebaseMappedData["contentType"] = environment.contentTypes.movie;
      firebaseMappedData["contentId"] = content.id;
      firebaseMappedData["contentSlug"] = content.slug;
      this.saveMovieToFirebase(firebaseMappedData);
    } else {
      firebaseMappedData["contentType"] = type;
      firebaseMappedData["contentId"] = tv_show_id;
      firebaseMappedData["contentSlug"] = tv_show_slug;
      firebaseMappedData["seasonId"] = seasonId;
      firebaseMappedData["episodeNumber"] = episodeNumber;
      this.saveTvEpisodeToFirebase(firebaseMappedData);
    }
  }
  saveMovieToFirebase(data) {
    this.firestoreService.saveMovieAtLast(data)
      .then(res => {
        this.firestoreService.saveMovie(data);
      })
  }
  saveTvEpisodeToFirebase(data) {
    this.firestoreService.saveEpisodeAsLast(data)
      .then(
        (res) => {
          this.firestoreService.saveEpisodeIntoList(data);
        }
      )
  }
  mapPosterUrls(posters) {
    return [
      {
        type: "LANDSCAPE",
        data: posters.filter((poster) => ((poster.orientation_type == "LANDSCAPE" && poster.resolution_type == "ONE_X") || (poster.orientation_type == "LANDSCAPE")))
      },
      {
        type: "PORTRAIT",
        data: posters.filter((poster) => ((poster.orientation_type == "PORTRAIT" && poster.resolution_type == "ONE_X") || (poster.orientation_type == "PORTRAIT")))
      }
    ]
  }
  checkScreenCount(res, videoDetails, slug, isNextVideo, contentTypeForRq, inRelated?:boolean, vid?) {
    this.firestoreService.getConcurrentScreenCount()
      .then(
        (data) => {
          data.docs.forEach(async element => {
            let currentTime = new Date().getTime();
            let screenExpireTime = new Date(element.data().expireTime).getTime();
            if (screenExpireTime < currentTime) {
              this.removeExpireScreen(element.id);
            }
          });
          let screenCount = (this.authenticationService.currentUser().is_premium_user) || (this.authenticationService.currentUser().is_annual_premium_user) ? environment.premiumScreens : environment.freeScreens;
          if (!!data && data.docs.length < screenCount + 1 && data.docs.length != screenCount) {
            this.setPlayerContent(res, videoDetails, slug, isNextVideo, contentTypeForRq, inRelated, vid);
          } else {
            this.openScreenExceedWarningComponent(data.docs, res, videoDetails, slug, isNextVideo, contentTypeForRq, inRelated);
          }
        }
      );
  }
  openScreenExceedWarningComponent(data, resData, videoDetails, slug, isNextVideo, contentTypeForRq, inRelated?) {
    this.spinnerService.hide('player');
    this.matDialog.open(ScreenExceedWarningComponent, { data: data, width: "400px" })
      .afterClosed().subscribe(
        (res) => {
          for (let index = 0; index < res.length; index++) {
            let isPlay = (index == res.length - 1) ? true : false;
            this.removeExpireScreen(res[index], isPlay, contentTypeForRq, resData, videoDetails, slug, isNextVideo, inRelated);
          }
        });
  }
  removeExpireScreen(id, isPlay?: boolean, contentTypeForRq?: string, data?, videoDetails?, slug?, isNextVideo?, inRelated?) {
    this.firestoreService.removeScreenFromFireBase(id)
      .then(
        (res) => {
          if (isPlay && !inRelated) {
            this.getVideoDetail(contentTypeForRq, slug, videoDetails, isNextVideo);
          }
          if(inRelated) {
            this.getVideoDetail(contentTypeForRq, slug, videoDetails, isNextVideo, inRelated);
          }
        }
      );
  }
  removeIfFromRelatedExpireScreen(id, inRelated?:boolean, contentTypeForRq?: string, data?, videoDetails?, slug?, isNextVideo?) {
    this.firestoreService.removeScreenFromFireBase(id)
      .then(
        (res) => {
          this.getVideoDetail(contentTypeForRq, slug, videoDetails, isNextVideo, inRelated);
        }
      );
  }
  addScreen(data) {
    this.firestoreService.addNewScreen(data)
      .then(
        (res) => {
          sessionStorage.setItem("screenId", res.id);
        }
      );
  }
  updateScreen(id, data) {
    this.firestoreService.updateScreen(id, data)
      .then(
        (res) => {
          //  ;
        }
      ).catch(
        (error) => {
          this.popupService.openAlertMessageComponent("Sorry you have been removed by \nsomeone else", "info", "Screen Count Exceeded")
          this.router.navigateByUrl(this.returnUrl);
        }
      )
  }
  getCurrentWatchHours(contentId: number, title, time) {
    let date: string = new Date().toISOString().slice(0, 10);
    this.firestoreService.getWatchHours(contentId, date)
      .then(
        (res) => {
          let data = {
            title: title,
            totalWatchHours: (res.data()) ? res.data().totalWatchHours + time : time,
            totalWatchInMilliSec: (res.data()) ? res.data().totalWatchInMilliSec + (time * 3600 * 1000) : (time * 3600 * 1000)
          }

          this.updateWatchHours(contentId, date, data)
        }
      ).catch(
        (error) => {
        }
      )
  }
  updateWatchHours(contentId: number, date: string, data: any) {
    this.firestoreService.updateWatchHours(contentId, date, data)
      .then(
        (res) => {
        }
      ).catch(
        (error) => {
        }
      )
  }
}
