import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HttpService {
  constructor(private http: HttpClient) {
  }

  public get<T>(url: any, option?: any): any {
    if (option) {
      return this.http.get<T>(url, option);
    }
    return this.http.get<T>(url);
  }

  public post<T>(url: any, body: any, option?: any) {
    if (option) {
      return this.http.post<T>(url, body, option);
    } else {
      return this.http.post<T>(url, body);
    }
  }

  public put<T>(url: any, body: any) {
    return this.http.put<T>(url, body);
  }

  public delete<T>(url: any) {
    return this.http.delete<T>(url);
  }

  public patch<T>(url: any, body: any) {
    return this.http.patch<T>(url, body);
  }
}
