import { Component, NgZone, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { AuthenticationService } from 'src/app/_core/services/authentication.service';
import { ContentService } from 'src/app/_core/services/content.service';
import { PopupService } from 'src/app/_core/services/popup.service';

@Component({
  selector: 'app-home-report-problem',
  templateUrl: './home-report-problem.component.html',
  styleUrls: ['./home-report-problem.component.scss']
})
export class HomeReportProblemComponent implements OnInit {

  issues: any[] = [];
  customProblem: string;

  constructor(
    public dialogRef: MatDialogRef<HomeReportProblemComponent>,
    private contentService: ContentService,
    private authenticationService: AuthenticationService,
    private popupService: PopupService,
    private ngZone: NgZone,
  ) {  }

  ngOnInit() {
    this.getProblems();
  }
  getProblems() {
    this.contentService.getProblems(0, 10)
      .subscribe(
        (res) => {
          this.issues = res.problems;
        });
  }
  sendDefaultProblem(issue) {
    let body = {
      problem_type: "DEFAULT",
      id: issue.id,
      user_id: this.authenticationService.currentUser().user_id
    }
    this.reportProblem(body);
  }
  sendCustomProblem() {
    let body = {
      problem_type: "CUSTOM",
      message: this.customProblem,
      user_id: this.authenticationService.currentUser().user_id
    }
    this.reportProblem(body);
  }
  reportProblem(body) {
    this.contentService.homeReportProblem(body)
      .subscribe(
        (res) => {
          this.popupService.openAlertMessageComponent("Thank you, We will get to you.", "success", "Report Success")
            .subscribe(
              (res) => {
                this.dialogRef.close();
              }
            )
        });
  }
  closeDialog(){
    this.ngZone.run(() => {
      this.dialogRef.close()
    })
  }

}
