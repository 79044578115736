import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AuthenticationService } from 'src/app/_core/services/authentication.service';
import { DataService } from 'src/app/_core/services/data.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-otp',
  templateUrl: './otp.component.html',
  styleUrls: ['./otp.component.scss']
})
export class OtpComponent implements OnInit {

  username: string;
  errorMessage: string;
  time: number = 60;
  otp: number[] = [null, null, null, null];
  isArrowKey: boolean = false;
  setInterval: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    public dialogRef: MatDialogRef<OtpComponent>,
    private authenticationService: AuthenticationService,
    private matDialog: MatDialog,
    private router: Router,
    private dataService: DataService
  ) { }

  ngOnInit() {
    this.dialogRef.updateSize('400px');
    this.username = this.data.credential;
    this.selectRequestType();
  };
  selectRequestType() {
    switch (this.data.type) {
      case 'new-user':
        this.sendNewUserOtp();
        break;
      case 'password-reset':
        this.sendPasswordResetOtp();
        break;
      case 'change-username':
        this.sendChangeUsernameOtp();
        break;
      default:
        break;
    }
  }
  sendNewUserOtp() {
    let body = { login_type: this.data.login_type };
    body[`${this.data.login_type}`] = this.data.credential;

    this.authenticationService.createNewOtp(body)
      .subscribe(
        (res) => {
          //  ;
          this.countdown();
        },
        (error) => {
          this.errorMessage = error.error.error;
        });
  }
  sendPasswordResetOtp() {
    let body = { login_type: this.data.login_type };
    body[`${this.data.login_type}`] = this.data.credential;

    this.authenticationService.passwordResetOtp(body)
      .subscribe(
        (res) => {
          //  ;
          this.countdown();
        },
        (error) => {
          this.errorMessage = error.error.error;
        });
  }
  sendChangeUsernameOtp(){
    if(this.data.login_type_email){
      let body = {old_email: this.data.old_credential, new_email: this.data.credential, login_type:"email"};
      this.authenticationService.changeEmailOtp(body)
      .subscribe(
        (res) => {
          //  ;
          this.countdown();
        },
        (error) => {
          this.errorMessage = error.error.error;
        });
    }
    if(!this.data.login_type_email){
      let body = {old_mobile: this.data.old_credential, new_mobile: this.data.credential, login_type:"mobile"};
      this.authenticationService.changeMobileOtp(body)
      .subscribe(
        (res) => {
          //  ;
          this.countdown();
        },
        (error) => {
          this.errorMessage = error.error.error;
        });
    }
  }
  countdown() {
    const temp = this;
    this.setInterval = setInterval(function () {
      if (temp.time == 0) {
        clearInterval(temp.setInterval);
      }
      temp.time -= 1;
    }, 1000)
  }
  validateKeyUp(nextId, event, prevId, nextIndex) {
    const code = event.keyCode | event.which;
    var nextElement;
    var previousElement;
    if (nextId) {
      nextElement = document.getElementById(nextId);
    }
    if (prevId) {
      previousElement = document.getElementById(prevId);
    }

    switch (true) {
      case (code == 8)://back space code
        break;
      case (code == 37)://left code
        setTimeout(() => { previousElement.focus(); }, 0);
        this.isArrowKey = true;
        break;
      case (code == 39)://right code
        setTimeout(() => { nextElement.focus(); }, 0);
        this.isArrowKey = true;
        break;
      case ((96 <= code && code <= 105) || (48 <= code && code <= 57)): //number code
        if (nextElement) {
          setTimeout(() => { nextElement.focus(); }, 0);
        }
        if (!this.isArrowKey) {
          this.otp[nextIndex] = null;
        }
      case (48 <= code && code <= 57): //number code
        if (nextElement) {
          setTimeout(() => { nextElement.focus(); }, 0);
        }
        if (!this.isArrowKey) {
          this.otp[nextIndex] = null;
        }
      default:
        this.validateOTP();
        break;
    }
  }
  validateOTP() {
    if ((!!this.otp[0] || this.otp[0] == 0) && (!!this.otp[1] || this.otp[1] == 0) && (!!this.otp[2] || this.otp[2] == 0) && (!!this.otp[3] || this.otp[3] == 0)) {
      let body = {
        otp: this.otp.join(""),
        username: this.username
      }
      this.errorMessage = null;
      if (this.data.type == "change-username")
        {
          let body = {
            otp: this.otp.join(""),
            username: this.data.old_credential
          }
          this.verifyChangeUsernameOTP(body);
        }
    }
  }
  verifyChangeUsernameOTP(body){
    if(this.data.login_type_email){
      this.authenticationService.verifyChangeEmailOtp(body)
      .subscribe(
        (res) => {
          this.dialogRef.close();
          this.logout();
        },
        (error) => {
          this.errorMessage = error.error.error;
        });
    }
    if(!this.data.login_type_email){
      this.authenticationService.verifyChangeMobileOtp(body)
      .subscribe(
        (res) => {
          this.dialogRef.close();
          this.logout();
        },
        (error) => {
          this.errorMessage = error.error.error;
        });
    }
  }
  logout() {
    this.router.navigate(["/home"]);
    this.authenticationService.logout();
    this.dataService.refreshLoginCredential(true);
  }
}
