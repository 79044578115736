import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-payment-main',
  templateUrl: './payment-main.component.html',
  styleUrls: ['./payment-main.component.scss']
})
export class PaymentMainComponent implements OnInit {

  isShowHeader: boolean = true;

  constructor(
  ) { }

  ngOnInit() {
    let router = window.location.pathname.split("/");
    if (router[2] == "card-change" || router[2] == "account" || router[2] == "home" || router[2] == "alert") {
      this.isShowHeader = false;
    } else {
      this.isShowHeader = true;
    }
  }
}
