import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { CompleteStepActionComponent } from 'src/app/shared/complete-step-action/complete-step-action.component';
import { AuthenticationService } from 'src/app/_core/services/authentication.service';
import { DataService } from 'src/app/_core/services/data.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss']
})
export class SigninComponent implements OnInit, OnDestroy {

  accStatus: string;
  isStepper: boolean = false;
  completeStep: any[]=[];
  subscription1: Subscription;
  subscription2: Subscription;
  subscription3: Subscription;

  userData: any;
  user:any;

  constructor(
    private dataService: DataService, 
    private authenticationSerice: AuthenticationService,
    private route: ActivatedRoute,
    private router: Router,
    private matDialog: MatDialog
  ) { 
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }

  ngOnInit() {
    this.user = this.authenticationSerice.currentUser()
    this.subscription1 = this.dataService.accountStatus.subscribe(status => this.accStatus = status);
    this.subscription2 = this.dataService.isStepper.subscribe(has => this.isStepper = has);
    this.subscription3 = this.dataService.accComplete.subscribe(step => this.completeStep = step);

    this.route.queryParams.subscribe(res => {
      if(Object.keys(res).length != 0) {
        if(res.data) {
          this.userData = JSON.parse(window.atob(res.data));
          if(this.userData.account) {
            this.dataService.isStepperAvailable(true)
          }
        }
      }
    })
  }

  checkStep(steps, arr) {
    return steps.toString() == arr.toString();
  }

  ngOnDestroy() {
    this.subscription1.unsubscribe();
    this.subscription2.unsubscribe();
    this.subscription3.unsubscribe();
  }

  routeCheck() {
    switch (this.router.url.split('/')[2]) {
      case 'login':
        this.router.navigate(['/home'])
        break;
      case 'new-account':
        this.matDialog.open(CompleteStepActionComponent, {
          width: '350px',
          disableClose: true,
          data: {
            message: 'You are two steps away from creating the dflix account. Are you sure you want to quit?'
          }
        })
        break;
      case 'package-select':
        if(this.userData) {
          this.matDialog.open(CompleteStepActionComponent, {
            width: '350px',
            disableClose: true,
            data: {
              message: 'You are at the final few step of creating the dflix account. Are you sure you want to quit?'
            }
          })
        } else {
          this.router.navigate(['/home'])
        }
        break;
    }
  }

}
