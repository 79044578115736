import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from 'src/app/_core/services/data.service';
import { HelperService } from 'src/app/_core/services/helper.service';
import { PopupService } from 'src/app/_core/services/popup.service';
import { WatchListService } from 'src/app/_core/services/watch-list.service';
import { DeviceInfoService } from 'src/app/_core/services/device-info.service';
import { ContentService } from 'src/app/_core/services/content.service';
import { environment } from 'src/environments/environment';
import { MatDialog } from '@angular/material';
import { PlayerService } from 'src/app/_core/services/player.service';
import { Platform } from '@angular/cdk/platform';
import { DownloadIosComponent } from '../download-ios/download-ios.component';
import { FirestoreService } from 'src/app/_core/services/firestore.service';
import { AuthenticationService } from 'src/app/_core/services/authentication.service';

declare var $:any;

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss']
})
export class SliderComponent implements OnInit, OnDestroy, OnChanges{

  @Input() sliders: any;
  watchList: any[] = [];
  @Output() getWatchList = new EventEmitter();
  addWatchListSubscriber: any;
  video: boolean = false;
  timeOut: any = null;
  // autoSlide: boolean = false;
  videoRunning: boolean = false;
  audioButton: boolean = false;
  audioMuted: boolean = true;
  videoElement: any;
  videoPlaying: boolean = false;

  netSpeed: any;
  isCorrectSpeed: boolean = false;
  isMobile: boolean = false;
  isWatchedApiRequested: boolean = false;

  sliderLength: number;
  currentUser: any;

  isInwatchLit: boolean = false;


  constructor(
    private router: Router,
    private helperService: HelperService,
    private dataService: DataService,
    private watchListService: WatchListService,
    private popupService: PopupService,
    private deviceInfoService: DeviceInfoService,
    private contentService: ContentService,
    private matDialog: MatDialog,
    private playerService: PlayerService,
    private platform: Platform,
    private firestoreService: FirestoreService,
    private authenticationService: AuthenticationService
  ) { }


  ngOnDestroy(): void {
    this.addWatchListSubscriber.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.sliders.currentValue.length > 0) {
      this.sliderLength = changes.sliders.currentValue.length;
      this.currentUser = this.authenticationService.currentUser()
      this.sliders.forEach(element => {
        this.checkInWatchList(element)
      });
      this.checkVideoLoad(changes.sliders.currentValue[0].review_video_url)
    }
  }

  ngOnInit(): void {
    this.currentUser = this.authenticationService.currentUser()
    this.subscribeToWatchList();
    if(this.deviceInfoService.getDeviceType() == 'MOBILE') {
      this.isMobile = true;
    }
  }

  // loadSliderVideo() {
  //   setTimeout(() => {
  //     this.isCorrectSpeed = true;
  //   }, 5000);
  // }

  checkVideoLoad(link) {
    const temp = this;
    const start = Date.now();
    const url = link;
    const xhr = new XMLHttpRequest();
    let end;
    let size;
    let time;
    let speed;
    xhr.addEventListener("progress", updateProgress);
    xhr.addEventListener("load", transferComplete);


    xhr.open('GET', url);
    xhr.send();

    function updateProgress(oEvent) {
      if (oEvent.lengthComputable) {
        if (!size) {
          size = oEvent.total;
        }
      }
    }

    function transferComplete(evt) {
      end = Date.now();
      time = (end - start) / 1000;
      speed = parseFloat(`${size/time/10}`).toFixed(2);
      console.log(speed)
      if(speed > 3) {
        temp.isCorrectSpeed = true;
      } else {
        temp.isCorrectSpeed = false;
      }

    }

  }


  subscribeToWatchList() {
    this.addWatchListSubscriber = this.dataService.addWatchListSource.subscribe(
      (watchList) => {
        if (!!watchList) {
          this.watchList = watchList;
        }
      }
    );
  }
  // playVideo(slider) {
  //   if(slider.content.type == 'LIVE_CHANNEL') {
  //     this.router.navigate(['account/login'], {queryParams: {returnUrl: `other/${this.helperService.changeRouterLink(slider.content.type)}/${slider.content.slug}` }});
  //   } else {
  //     this.router.navigate(['other', this.helperService.changeRouterLink(slider.content.type), slider.content.slug]);
  //     var element: any = document.getElementById("nav-bar");
  //     element.classList.remove("nav-bar-color");
  //     element.classList.add('nav-bar-color-scroll');
  //   }
  // }
  addToWatchList(slider) {
    this.isWatchedApiRequested = true;
    let data = {
      description: (slider.content.description) ? slider.content.description.split("").splice(0, 70).join("") : '',
      genre: slider.content.genres.map((g) => g['name']),
      id: slider.content.id,
      name: slider.content.slug,
      posters: slider.posters,
      run_time: null,
      slug: slider.content.slug,
      status: null,
      type: slider.content.type,
      time: new Date().toISOString()
    }
    this.contentService.getContentBySlug(data.slug,this.getContentType(data.type)).subscribe(res => {
      data["all_posters"] = res.all_posters ? res.all_posters : null;
      this.watchListService.addTowatchList(data)
        .then(
          (res) => {
            this.isWatchedApiRequested = false;
            this.getWatchList.emit();
          }
        );
    })
  }
  removeFromWatchList(slider) {
    this.isWatchedApiRequested = true;
    this.watchListService.removeFromWatchList(slider.content.id)
      .then(
        (res) => {
          this.isWatchedApiRequested = false;
          this.getWatchList.emit();
        }
      );
  }
  checkInWatchList(slider) {
    if (this.watchList.length != 0) {
      let isAvailable = this.watchList.find(data => data.id == slider.content.id);
      return (!!isAvailable && this.currentUser) ? true : false;
    } else {
      return false;
    }
  }
  addArrowAnimation(id, i?, url?) {
    if(id) {
      var ele = document.getElementById(id)
      if(ele) {
        ele.classList.add("path");
        document.getElementById(`${id}-arrow`).classList.add(`${id}-arrow`);
        this.videoPlay();
        setTimeout(() => {
          ele.classList.remove("path");
          var ele1 = document.getElementById(`${id}-arrow`)
          ele1 ? ele1.classList.remove(`${id}-arrow`) : null;
        }, 2000);
      }
    }
    if(url != null && !this.isMobile) {
      this.videoElement = <HTMLVideoElement>document.getElementById('video-'+i);
      if(this.videoElement) {
        this.videoElement.pause();
      }
      this.videoPlaying = false;
    }
    if(this.isCorrectSpeed) {
      if(id == 'next') {
        var currentIndex:any = $('div.active').index() + 1;
        var sliderVideo = <HTMLVideoElement>document.getElementById('video-'+currentIndex);
        if(sliderVideo) sliderVideo.play();
      } else {
        var currentIndex:any = $('div.active').index() - 1;
        var sliderVideo = <HTMLVideoElement>document.getElementById('video-'+currentIndex);
        if(sliderVideo) sliderVideo.play();
      }
    }

  }
  showVoteAlert() {
    this.popupService.openAlertMessageComponent("Vote can not be done through web. \nUse Reality App", "info", "Download Reality App")
  }
  imgLoad(index: number){
    this.videoPlay();
  }
  videoPlay(){
    this.video = false;
    this.audioButton = false;
    if(this.timeOut){
      clearTimeout(this.timeOut);
      this.timeOut = null;
    }
    this.timeOut = setTimeout(() => {
      this.video = true;
      setTimeout(() => {
        if (!this.videoRunning){
          this.addArrowAnimation('next');
          this.videoRunning = false;
          this.audioButton = false;
          var ele = document.getElementById('change-next-slide')
          ele ? ele.click() : null;
        }
      },2000);
    }, 5000);
  }

  videoEnded(index: number){
    var activeSlide = document.getElementById('slider-'+index).classList.contains("active");
    var sliderVideo = <HTMLVideoElement>document.getElementById('video-'+index);
    if(sliderVideo) {
      sliderVideo.pause();
      sliderVideo.currentTime = 0;
    }
    if(activeSlide){
      this.addArrowAnimation('next');
      this.videoRunning = false;
      this.audioButton = false;
      var ele = document.getElementById('change-next-slide');
      ele ? ele.click() : null;
    }
  }

  startVideo(index: number){
      this.audioButton = true;
      this.videoPlaying = true;
      var activeSlide = document.getElementById('slider-'+index).classList.contains("active");
      if(activeSlide){
        if(this.isCorrectSpeed) {
          this.videoRunning = true;
          this.audioButton = true;
          this.videoPlaying = true;
        }
      }
  }
  canPlay(index: number){
    var activeSlide = document.getElementById('slider-'+index).classList.contains("active");
    if(activeSlide){
      if(this.isCorrectSpeed) {
        var sliderVideo = <HTMLVideoElement>document.getElementById('video-'+index);
        sliderVideo.play();
        sliderVideo.muted = true;
        this.audioMuted = true;
        this.videoRunning = true;
        this.audioButton = true;
      }
    }
  }


  // controls
  audio(index: number){
    var sliderVideo = <HTMLVideoElement>document.getElementById('video-'+index);
    if(this.audioMuted){
      sliderVideo.muted = false;
      this.audioMuted = false;
    }else{
      sliderVideo.muted = true;
      this.audioMuted = true;
    }
  }
  play(index: number) {
    var sliderVideo = <HTMLVideoElement>document.getElementById('video-'+index);
    if(this.videoPlaying) {
      sliderVideo.pause()
      this.videoPlaying = false;
    } else {
      sliderVideo.play()
      this.videoPlaying = true;
    }
  }

  getContentType(type) {
    let contentType;
    switch (type) {
      case 'MOVIE':
        contentType = environment.contentTypesForRq.movie;
        break;
      case 'TV_SHOW':
        contentType = environment.contentTypesForRq.tvShow;
        break;
      case 'TV_EPISODE':
        contentType = environment.contentTypesForRq.tvEpisode;
        break;
      case 'VIDEO_CHANNEL':
        contentType = environment.contentTypesForRq.videoChannel;
        break;
      case 'LIVE_CHANNEL':
        contentType = environment.contentTypesForRq.liveChannel;
        break;
    }
    return contentType;
  }

  playVideo(slider) {

    if(this.platform.IOS) {
      this.matDialog.open(DownloadIosComponent, {
        disableClose: true
      })
    } else {
      this.playerService.returnUrl = this.router.url;
      if(slider.content.type == 'LIVE_CHANNEL') {
        this.router.navigate(['other', this.helperService.changeRouterLink(slider.content.type), slider.content.slug]);
      } else {
        if(this.currentUser) {
          this.firestoreService.getContentInWatchHistory(slider.content.id).then(doc => {
            if(doc.exists) {
              let videoDetails: any = {
                autoplay: true,
                tagUrl: '',
                last_time: doc.data().lastWatchedPosition / 1000,//last time in seconds
                tv_show_slug: doc.data().contentSlug,
                tv_show_id: doc.data().contentId,
                tv_show_name: doc.data().contentDetailSlug,
                episodeNumber: doc.data().episodeNumber,
                seasonId: doc.data().seasonId
              };
              this.playerService.playVideo(doc.data().contentType, doc.data().contentDetailSlug, videoDetails, false);
            } else {
              let videoDetails: any = {
                autoplay: true,
                tagUrl: ' ',
                last_time: null,
              };
              let data = this.getContentType(slider.content.type);
              this.playerService.playVideo(data, slider.content.slug, videoDetails, false);
            }
          })
        } else {
          let videoDetails: any = {
            autoplay: true,
            tagUrl: ' ',
            last_time: null,
          };
          let data = this.getContentType(slider.content.type);
          this.playerService.playVideo(data, slider.content.slug, videoDetails, false);
        }
      }
    }
  }
}
