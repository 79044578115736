import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/_core/services/authentication.service';
import { ContentService } from 'src/app/_core/services/content.service';
import { CookieService } from 'src/app/_core/services/cookie.service';
import { DataService } from 'src/app/_core/services/data.service';
import { DeviceInfoService } from 'src/app/_core/services/device-info.service';
import { FirestoreService } from 'src/app/_core/services/firestore.service';
import { PlayerService } from 'src/app/_core/services/player.service';
import { WatchListService } from 'src/app/_core/services/watch-list.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-poster',
  templateUrl: './poster.component.html',
  styleUrls: ['./poster.component.scss'],
})
export class PosterComponent implements OnInit, OnDestroy {

  @Input() category: any = [];
  @Input() keyId: string = '';
  @Input() index: any;
  @Input() borderRadius: string = '';
  @Input() isShowName: boolean = false;
  @Input() isHover: boolean = false;
  @Output() playVideo = new EventEmitter();
  @Input() isContinueWatching: boolean = false;
  @Input() isSearch: boolean = true;
  @Output() getWatchList = new EventEmitter();
  requestBody: any = {};
  isAddedToWatchList: boolean = false;
  @Input() widthType: string = "inherit";
  @Input() isWatchList: boolean = true;
  isPremiumContent: boolean = false;
  isPremiumContents: boolean = false;

  isWatchedApiRequested: boolean = false;
  isPlayClick: boolean = false;
  isTrailerClick: boolean = false;
  isEpisodeClick: boolean = false;
  watchListSubscriber: any;
  @Input() isNormalTransition: boolean = true;
  buttonValue: string = "";

  currentUser:any;
  isMobile: boolean = false;


  constructor(
    private cookieService: CookieService,
    private dataService: DataService,
    private watchListService: WatchListService,
    private firestoreService: FirestoreService,
    private authenticationService: AuthenticationService,
    private deviceInfoService: DeviceInfoService,
    private contentService: ContentService,
    private playerService: PlayerService,
    private router: Router
  ) { }
  ngOnDestroy(): void {
    this.watchListSubscriber.unsubscribe();
  }

  ngOnInit() {
    this.currentUser = this.authenticationService.currentUser()
    if(this.deviceInfoService.getDeviceType() == 'MOBILE') {
      this.isMobile = true;
    }
    this.subscribeToWatchList();
    this.checkIsPremiumContent();
    this.checkIssPremiumContent();
    this.selectButtonValue();
  }
  selectButtonValue() {
    switch (this.category.fullData.type) {
      case environment.contentTypes.videoChannel:
        this.buttonValue = "Show Videos";
        break;
      case environment.contentTypes.liveChannel:
        this.buttonValue = "Show Programmes"
        break;
      case environment.contentTypes.tvShow:
        this.buttonValue = "Show Episodes"
        break;
      default:
        this.buttonValue = "Show Contents"
        break;
    }
  }
  subscribeToWatchList() {
    this.watchListSubscriber = this.dataService.addWatchListSource.subscribe(
      (watchList) => {
        if (!!watchList) {
          let isAvailable = watchList.find(data => data.id == this.category.fullData.id);
          this.isAddedToWatchList = (!!isAvailable && this.currentUser) ? true : false;
        }
      }
    );
  }
  checkIsPremiumContent() {
    let isFreeToWatchForPremium = false;
    let isFreeToWatchForFree = false;
    let isFreeToWatchForAnnual = false;


    if (!!this.category.fullData.monetization && !!this.category.fullData.monetization.free_to_watch) {
      isFreeToWatchForPremium = (!!this.category.fullData.monetization && !!this.category.fullData.monetization.free_to_watch) ? ((!!this.category.fullData.monetization.free_to_watch.find(ele => ele == "PREMIUM")) ? true : false) : false;
      isFreeToWatchForFree = (!!this.category.fullData.monetization && !!this.category.fullData.monetization.free_to_watch) ? ((!!this.category.fullData.monetization.free_to_watch.find(ele => ele == "FREE")) ? true : false) : false;
      isFreeToWatchForAnnual = (!!this.category.fullData.monetization && !!this.category.fullData.monetization.free_to_watch) ? ((!!this.category.fullData.monetization.free_to_watch.find(ele => ele == "ANNUAL_PREMIUM")) ? true : false) : false;

    }
    // if (!!this.category.fullData.monetization && !!this.category.fullData.monetization.plans) {
    //   isFreeToWatchForPremium = (!!this.category.fullData.monetization && !!this.category.fullData.monetization.plans) ? ((!!this.category.fullData.monetization.plans.find(ele => ele.package_type == "PREMIUM")) ? true : false) : false;
    //   isFreeToWatchForFree = (!!this.category.fullData.monetization && !!this.category.fullData.monetization.plans) ? ((!!this.category.fullData.monetization.plans.find(ele => ele.package_type == "FREE")) ? true : false) : false;
    //   isFreeToWatchForAnnual = (!!this.category.fullData.monetization && !!this.category.fullData.monetization.plans) ? ((!!this.category.fullData.monetization.plans.find(ele => ele.package_type == "ANNUAL_PREMIUM")) ? true : false) : false;
    // }

    this.isPremiumContent = (isFreeToWatchForFree) ? false : ((isFreeToWatchForPremium) || (isFreeToWatchForAnnual) ? true : false);
  }

  checkIssPremiumContent() {
    let isFreeToWatchForPremium = false;
    let isFreeToWatchForFree = false;
    let isFreeToWatchForAnnual = false;
    if (!!this.category.fullData.monetization && !!this.category.fullData.monetization.plans) {
      isFreeToWatchForPremium = (!!this.category.fullData.monetization && !!this.category.fullData.monetization.plans) ? ((!!this.category.fullData.monetization.plans.find(ele => ele.package_type == "PREMIUM")) ? true : false) : false;
      isFreeToWatchForFree = (!!this.category.fullData.monetization && !!this.category.fullData.monetization.plans) ? ((!!this.category.fullData.monetization.plans.find(ele => ele.package_type == "FREE")) ? true : false) : false;
      isFreeToWatchForAnnual = (!!this.category.fullData.monetization && !!this.category.fullData.monetization.plans) ? ((!!this.category.fullData.monetization.plans.find(ele => ele.package_type == "ANNUAL_PREMIUM")) ? true : false) : false;
    }
    this.isPremiumContents = (isFreeToWatchForFree) || (isFreeToWatchForPremium) || (isFreeToWatchForAnnual) ? true : false;
  }
  getMovieRunTime(runTime: number) {
    return Math.floor(runTime / 60) + 'hr ' + (runTime % 60).toFixed(0) + 'min';
  }
  passData(isPlay) {
    if(this.currentUser) {
      if (this.isContinueWatching) {
        this.playVideo.emit({ data: this.category.fullData, isContinueWatching: true, isPlay: true });
      } else {
        this.firestoreService.getContentInWatchHistory(this.category.fullData.id).then(doc => {
          if(doc.exists) {
              this.playVideo.emit({ data: isPlay ? doc.data() : this.category.fullData, isContinueWatching: true, isPlay: isPlay });
          } else {
            this.playVideo.emit({ data: this.category.fullData, isContinueWatching: false, isPlay: isPlay });
          }
        })
      }
    } else {
      this.playVideo.emit({ data: this.category.fullData, isContinueWatching: false, isPlay: isPlay });
    }
  }
  passMinimalData() {
    if (!this.isHover) {
      this.playVideo.emit(this.category.fullData);
    } else {
      this.passData(false);
    }
  }

  playTrailer() {
    let videoDetails: any = {
      autoplay: true,
      tagUrl: '',
      last_time: null,
      manifestUri: this.category.fullData.bonus_content.cdn_url
    };
    this.playerService.returnUrl = this.router.url;
    let customizedData = {
      caption: `Trailer of ${this.category.fullData.bonus_content.name}`,
      isTrailer: true
    };
    this.playerService.navigateToPlayer({ type: "trailer" }, videoDetails, customizedData, null);
  }

  addToWatchList() {
    this.isWatchedApiRequested = true;
    let data = this.category.fullData;
    data["time"] = new Date().toISOString();
    data.description = (!!data.description) ? data.description.split("").splice(0, 70).join("") : '';
    delete data.monetization;
    delete data.sequence_no;
    this.contentService.getContentBySlug(data.slug,this.getContentType(data.type)).subscribe(res => {
      if(res.all_posters) {
        data["all_posters"] = res.all_posters;
      }
      this.watchListService.addTowatchList(data)
        .then(
          (res) => {
            this.isWatchedApiRequested = false;
            this.getWatchList.emit();
          }
        );
    })
  }
  removeFromWatchList() {
    this.isWatchedApiRequested = true;
    this.watchListService.removeFromWatchList(this.category.fullData.id)
      .then(
        (res) => {
          this.isWatchedApiRequested = false;
          this.getWatchList.emit(this.index);
        }
      );
  }
  getProfileId() {
    const profile = JSON.parse(this.cookieService.getCookie(environment.cookies.currentUser));
    return profile.profile_id;
  }
  isAvailableInWatchList(watchList: any[], id) {
    let isAvailable = watchList.find(data => data.id == id);
    return (!!isAvailable) ? true : false;
  }

  getContentType(type) {
    let contentType;
    switch (type) {
      case 'MOVIE':
        contentType = environment.contentTypesForRq.movie;
        break;
      case 'TV_SHOW':
        contentType = environment.contentTypesForRq.tvShow;
        break;
      case 'LIVE_CHANNEL':
        contentType = environment.contentTypesForRq.liveChannel;
        break;
      case 'TV_EPISODE':
        contentType = environment.contentTypesForRq.tvEpisode;
        break;
      case 'VIDEO_CHANNEL':
        contentType = environment.contentTypesForRq.videoChannel;
        break;
    }
    return contentType;
  }
}
