import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AuthenticationService } from 'src/app/_core/services/authentication.service';

@Component({
  selector: 'app-alert-action',
  templateUrl: './alert-action.component.html',
  styleUrls: ['./alert-action.component.scss']
})
export class AlertActionComponent implements OnInit {

  message: string;
  id: any;
  constructor(
    private dialogRef: MatDialogRef<AlertActionComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private authenticationService: AuthenticationService
  ) {
    this.message = data.message;
    this.id = data.id;
  }

  ngOnInit() {
  }
  close(isOk) {
    this.dialogRef.close(isOk);
  }

  update(isOk) {
    if(this.id != null) {
      this.authenticationService.verifyStatusComplete(this.id).subscribe(res => {
        let user = this.authenticationService.currentUser();
        user.user_account_status = "COMPLETED"
        this.authenticationService.setTokenCookie(user);
        sessionStorage.removeItem('register');
        this.dialogRef.close(isOk)
      })
    } else {
      this.dialogRef.close(isOk)
    }
  }

}
