import { Injectable } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { HelperService } from './helper.service';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  public playNextVideoSource = new BehaviorSubject(null);
  initialVideoCall = this.playNextVideoSource.asObservable();

  public seasonsSource = new BehaviorSubject(null);
  initialSeasonSource = this.seasonsSource.asObservable();

  public watchedListSource = new BehaviorSubject(null);
  initialWatchedListSource = this.watchedListSource.asObservable();

  public currentVideo = new BehaviorSubject(null);
  initialCurrentVideo = this.currentVideo.asObservable();

  public addWatchListSource = new BehaviorSubject(null);
  initialAddWatchListSource = this.addWatchListSource.asObservable();

  public refreshLoginCredentail = new BehaviorSubject(null);
  initialLoginCredentail = this.refreshLoginCredentail.asObservable();

  public relatedContentSource = new BehaviorSubject(null);
  initialRelatedContent = this.relatedContentSource.asObservable();

  public selectedRelatedContentSource = new BehaviorSubject(null);
  initialSelectedRelatedContent = this.selectedRelatedContentSource.asObservable();

  public accountStatus = new BehaviorSubject(null);
  initialAccountStatus = this.accountStatus.asObservable();

  public isStepper = new BehaviorSubject(false);
  initialStepper = this.isStepper.asObservable();

  public accComplete = new BehaviorSubject([]);
  completeStep = this.accComplete.asObservable();

  public fireUser = new BehaviorSubject(null);
  passFireUser = this.fireUser.asObservable()

  public isScrolled = new BehaviorSubject(false);
  passScroll = this.isScrolled.asObservable();

  public liveData: any;

  public intervalId: any;

  constructor(
    private helperService: HelperService,
    private metaService: Meta
  ) { }
  passSelectedRelatedContent(relatedContent) {
    this.selectedRelatedContentSource.next(relatedContent);
  }

  passRelatedContent(relatedContents) {
    this.relatedContentSource.next(relatedContents);
  }
  passSeasonsData(seasonData) {
    this.seasonsSource.observers = this.seasonsSource.observers.slice(this.seasonsSource.observers.length - 1);
    this.seasonsSource.next(seasonData);
  }
  passWatchedListData(watchedList) {
    this.watchedListSource.observers = this.watchedListSource.observers.slice(this.watchedListSource.observers.length - 1);
    this.watchedListSource.next(watchedList);
  }
  passCurrentVideoData(currentVideo) {
    // this.currentVideo.observers = this.currentVideo.observers.slice(this.currentVideo.observers.length - 1);
    this.currentVideo.next(currentVideo);
  }
  passAddWatchListData(addWatchList) {
    this.addWatchListSource.next(addWatchList);
  }
  refreshLoginCredential(isRefresh) {
    this.refreshLoginCredentail.next(isRefresh);
  }
  setHeaderValues(title, desc: string, posters) {
    this.metaService.updateTag(
      {
        property: "og:title",
        content: title
      }
    );
    this.metaService.updateTag(
      {
        property: "og:url",
        content: window.location.href
      }
    );
    this.metaService.updateTag(
      {
        property: "og:description",
        content: desc.split("").splice(0, 50).join("")
      }
    );
    this.metaService.updateTag(
      {
        property: "og:image",
        content: this.helperService.getPosterUrlByFiltering(posters, ["ONE_X", "TWO_X", "THREE_X", "FOUR_X"], "LANDSCAPE", true)
      }
    );
  }

  passAccountStatus(status) {
    this.accountStatus.next(status);
  }

  isStepperAvailable(isAvailable) {
    this.isStepper.next(isAvailable)
  }

  passCompleteStep(step) {
    this.accComplete.next([...this.accComplete.getValue(), step]);
  }

  passFireStoreUser(user) {
    this.fireUser.next(user)
  }

  passScrollEvent(scroll) {
    this.isScrolled.next(scroll)
  }

}
