import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import * as firebase from 'firebase/app';
import 'firebase/analytics'
import 'firebase/auth'
import { environment } from 'src/environments/environment';
import { CookieService } from './cookie.service';
import { DatePipe } from '@angular/common';
import { DataService } from './data.service';

@Injectable({
  providedIn: 'root'
})
export class FirestoreService {

  private snapshot: any;

  constructor(
    private firestore: AngularFirestore,
    private cookieService: CookieService,
    private datePipe: DatePipe,
    private dataService: DataService
  ) {
    firebase.analytics()
  }

  // start watch history

  getWatchHistoryAll(contentTypes) {
    return this.firestore.firestore
          .collection(environment.firebaseNewProfile).doc(this.selectedProfile().id.toString())
          .collection('watchHistory')
          .where("contentType", "in", contentTypes)
          .orderBy("timestamp", "desc").limit(20)
          .get();
  }
  getContentInWatchHistory(contentId) {
    return this.firestore.firestore
            .collection(environment.firebaseNewProfile).doc(this.selectedProfile().id.toString())
            .collection('watchHistory').doc(contentId.toString())
            .get();
  }
  // getMovieContentInWatchHistory(contentId) {
  //   return this.firestore.firestore
  //         .collection(environment.firebaseNewProfile).doc(this.selectedProfile().id.toString())
  //         .collection('watchHistory').doc(contentId.toString())
  //         .get();
  // }
  getWatchedTvEpisodeList(contentId) {
    return this.firestore.firestore
            .collection(environment.firebaseNewProfile).doc(this.selectedProfile().id.toString())
            .collection('watchHistory').doc(contentId.toString())
            .collection('contentDetails')
            .orderBy("timestamp", "desc")
            .get();
  }
  getWatchedVideo(contentId, contentDetailId) {
    return this.firestore.firestore
            .collection(environment.firebaseNewProfile).doc(this.selectedProfile().id.toString())
            .collection('watchHistory').doc(contentId.toString())
            .collection('contentDetails').doc(contentDetailId.toString())
            .collection('dates')
            .orderBy("timestamp", "desc").limit(1)
            .get();
  }
  saveMovie(data) {
    let path = this.firestore.firestore
              .collection(environment.firebaseNewProfile).doc(this.selectedProfile().id.toString())
              .collection('watchHistory').doc(data.contentId.toString())
              .collection('dates').doc(this.datePipe.transform(new Date(), 'yyyy-MM-dd'));
    
    path.get().then(doc => {
      if(doc.exists) {
        data['webDuration'] = firebase.firestore.FieldValue.increment(15000)
        return path.update(data);
      } else {
        data['webDuration'] = firebase.firestore.FieldValue.increment(0)
        data['androidDuration'] = firebase.firestore.FieldValue.increment(0)
        data['iosDuration'] = firebase.firestore.FieldValue.increment(0)
        data['views'] = firebase.firestore.FieldValue.increment(0)
        return path.set(data);
      }
    }).catch(err => {
      console.log(err)
    })
    
    // return this.firestore.firestore.collection(environment.firebaseNewProfile).doc(this.selectedProfile().id.toString()).collection('watchHistory').doc(data.contentId.toString()).collection('dates').doc(this.datePipe.transform(new Date(), 'yyyy-MM-dd')).set(data);

  }

  saveMovieAtLast(data) {
    return this.firestore.firestore
    .collection(environment.firebaseNewProfile).doc(this.selectedProfile().id.toString())
    .collection('watchHistory')
    .doc(data.contentId.toString())
    .set(data);
  }

  saveEpisodeAsLast(data) {
    return this.firestore.firestore
    .collection(environment.firebaseNewProfile).doc(this.selectedProfile().id.toString())
    .collection('watchHistory').doc(data.contentId.toString())
    // .collection('contentDetails').doc(data.contentDetailId.toString())
    .set(data);
  }
  saveEpisodeIntoList(data) {
    let path = this.firestore.firestore
              .collection(environment.firebaseNewProfile).doc(this.selectedProfile().id.toString())
              .collection('watchHistory').doc(data.contentId.toString())
              .collection('contentDetails').doc(data.contentDetailId.toString())
              .collection('dates').doc(this.datePipe.transform(new Date(), 'yyyy-MM-dd'));
    
    path.get().then(doc => {
      if(doc.exists) {
        data['webDuration'] = firebase.firestore.FieldValue.increment(15000)
        return path.update(data);
      } else {
        data['webDuration'] = firebase.firestore.FieldValue.increment(0)
        data['androidDuration'] = firebase.firestore.FieldValue.increment(0)
        data['iosDuration'] = firebase.firestore.FieldValue.increment(0)
        data['views'] = firebase.firestore.FieldValue.increment(0)
        return path.set(data);
      }
    }).catch(err => {
      console.log(err)
    })

    // return null;
  }

  updateView(contentId, contentDetailId?) {
    if(contentId && contentDetailId == undefined) {
      let path = this.firestore.firestore
                .collection(environment.firebaseNewProfile).doc(this.selectedProfile().id.toString())
                .collection('watchHistory').doc(contentId.toString())
                .collection('dates').doc(this.datePipe.transform(new Date(), 'yyyy-MM-dd'));
      
      path.get().then(doc => {
        if(doc.exists && doc.data().lastWatchedPosition > 30000) {
          return path.update('views', firebase.firestore.FieldValue.increment(1));
        }
      }).catch(err => {
        console.log(err)
      })
    } else {
      if(contentId) {
        let path = this.firestore.firestore
                  .collection(environment.firebaseNewProfile).doc(this.selectedProfile().id.toString())
                  .collection('watchHistory').doc(contentId.toString())
                  .collection('contentDetails').doc(contentDetailId.toString())
                  .collection('dates').doc(this.datePipe.transform(new Date(), 'yyyy-MM-dd'));
        
        path.get().then(doc => {
          if(doc.exists && doc.data().lastWatchedPosition > 30000) {
            return path.update('views', firebase.firestore.FieldValue.increment(1));
          }
        }).catch(err => {
          console.log(err)
        })
      }
    }
  }

  removeIfVideoEnded(contentId) {
    return this.firestore.firestore
              .collection(environment.firebaseNewProfile).doc(this.selectedProfile().id.toString())
              .collection('watchHistory').doc(contentId.toString()).delete()
  }

  // end watch history
  addToWatchList(data) {
    return this.firestore.firestore.collection(environment.fireBaseProfile).doc(this.selectedProfile().id.toString()).collection('watchList').doc(data.id.toString()).set(data);
  }
  async getAddToWatchList(isPaginate: boolean) {
    if (isPaginate) {
      if (!!this.snapshot) {
        const last = this.snapshot.docs[this.snapshot.docs.length - 1];
        if (!!last) {
          const first = this.firestore.firestore.collection(environment.fireBaseProfile).doc(this.selectedProfile().id.toString()).collection('watchList').orderBy("time", "desc").startAfter(last.data().time).limit(environment.size);
          this.snapshot = await first.get();
          return this.snapshot;
        } else {
          return null;
        }
      } else {
        const first = await this.firestore.firestore.collection(environment.fireBaseProfile).doc(this.selectedProfile().id.toString()).collection('watchList').orderBy("time", "desc").limit(environment.size);
        this.snapshot = await first.get();
        return this.snapshot;
      }
    } else {
      return this.firestore.firestore.collection(environment.fireBaseProfile).doc(this.selectedProfile().id.toString()).collection('watchList').get();
    }
  }
  removeAddToWatchListContent(id: number) {
    return this.firestore.firestore.collection(environment.fireBaseProfile).doc(this.selectedProfile().id.toString()).collection('watchList').doc(id.toString()).delete();
  }
  findWatchContentById(id: number) {
    return this.firestore.firestore.collection(environment.fireBaseProfile).doc(this.selectedProfile().id.toString()).collection('watchList').doc(id.toString()).get();
  }
  setSnapshotToNull() {
    this.snapshot = null;
  }
  getConcurrentScreenCount() {
    return this.firestore.firestore.collection(environment.fireBaseScreen).doc(this.currentUser().user_id.toString()).collection('contents').get();
  }
  removeScreenFromFireBase(id) {
    return this.firestore.firestore.collection(environment.fireBaseScreen).doc(this.currentUser().user_id.toString()).collection('contents').doc(id.toString()).delete();
  }
  addNewScreen(data: any) {
    return this.firestore.firestore.collection(environment.fireBaseScreen).doc(this.currentUser().user_id.toString()).collection('contents').add(data);
  }
  updateScreen(documentId, data) {
    return this.firestore.firestore.collection(environment.fireBaseScreen).doc(this.currentUser().user_id.toString()).collection('contents').doc(documentId).update(data);
  }
  getWatchHours(contentId: number, date: string) {
    return this.firestore.firestore.collection(environment.fireBaseWatchHours).doc("contents").collection(contentId.toString()).doc(date).get();
  }
  updateWatchHours(contentId: number, date: string, data) {
    return this.firestore.firestore.collection(environment.fireBaseWatchHours).doc("contents").collection(contentId.toString()).doc(date).set(data);
  }
  // addWatchHours(contentId: number, date: string, data) {
  //   return this.firestore.firestore.collection(environment.fireBaseWatchHours).doc("contents").collection(contentId.toString()).;
  // }
  saveAdStartEvent(data: any) {
    firebase.analytics().logEvent('advertisement_start', data);
  }
  saveAdMiddleEvent(data: any) {
    firebase.analytics().logEvent('advertisement_middle', data);
  }
  saveAdEndEvent(data: any) {
    firebase.analytics().logEvent('advertisement_complete', data);
  }
  signUpWithFirebase(token) {
    firebase.auth().signInWithCustomToken(token)
      .then((userCredential) => {
        // Signed in
        var user = userCredential.user;
        this.dataService.passFireStoreUser(user)
        // ...
      })
      .catch((error) => {
        var errorCode = error.code;
        var errorMessage = error.message;
        // ...
      });
  }
  signOutFirebase() {
    firebase.auth().signOut().then(() => {
      // Sign-out successful.
    }).catch((error) => {
      // An error happened.
    });
  }
  public selectedProfile(): any {
    let selectedProfile = this.cookieService.getCookie(environment.cookies.selectedProfile)
    if (!!selectedProfile && selectedProfile != undefined && selectedProfile != 'undefined')
      return JSON.parse(selectedProfile);
    return null;
  }
  public currentUser(): any {
    if (this.cookieService.getCookie(environment.cookies.currentUser))
      return JSON.parse(this.cookieService.getCookie(environment.cookies.currentUser));
    return null;
  }
}
