import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoggedGuard } from '../_core/auth-guard/logged.guard';
import { PackageSelectComponent } from './package-select/package-select.component';
import { LoginPageComponent } from './signin/login-page/login-page.component';
import { NewAccountPageComponent } from './signin/new-account-page/new-account-page.component';
import { PasswordResetPageComponent } from './signin/password-reset-page/password-reset-page.component';
import { SigninComponent } from './signin/signin.component';

const routes: Routes = [
  {
    path: '',
    component: SigninComponent,
    children: [
      {
        path: 'login',
        component: LoginPageComponent,
        canActivate: [LoggedGuard]
      },
      {
        path: 'new-account/creation',
        component: NewAccountPageComponent
      },
      {
        path: 'package-select',
        component: PackageSelectComponent
      },
      {
        path: 'reset-password',
        component: PasswordResetPageComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AccountRoutingModule { }
