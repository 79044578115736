import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-otp',
  templateUrl: './otp.component.html',
  styleUrls: ['./otp.component.scss']
})
export class OtpComponent implements OnInit {

  otp: number;

  constructor(
    public dialogRef: MatDialogRef<OtpComponent>
  ) { }

  ngOnInit() {
    this.dialogRef.disableClose = true;
  }
  verifyOTP() {
    this.dialogRef.close(this.otp);
  }

}
