import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material';
import { AlertMessageComponent } from 'src/app/shared/alert-message/alert-message.component';

@Injectable({
  providedIn: 'root'
})
export class PopupService {

  constructor(
    private dialogRef: MatDialog
  ) { }
  openAlertMessageComponent(message: string, type: string, header?: string) {
    return this.dialogRef.open(AlertMessageComponent,
      {
        data: {
          message: message.replace(/\n/g, '<br/>'),
          type: type,
          header: header
        },
        disableClose: true,
        maxWidth: '350px',
        minWidth: '350px'
      })
      .afterClosed();
  }
}
