import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { EntryRoutingModule } from './entry-routing.module';
import { MainComponent } from './main/main.component';
import { HeaderComponent } from './header/header.component';
import { MaterialModule } from '../material/material.module';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { SharedModule } from '../shared/shared.module';
import { WatchListComponent } from './watch-list/watch-list.component';
import { PaymentMainComponent } from './payment-main/payment-main.component';
import { PaymentHoldErrorComponent } from './payment-hold-error/payment-hold-error.component';


@NgModule({
  declarations: [MainComponent, HeaderComponent, WatchListComponent, PaymentMainComponent, PaymentHoldErrorComponent],
  imports: [
    CommonModule,
    EntryRoutingModule,
    MaterialModule,
    NgxSkeletonLoaderModule,
    SharedModule
  ],
  exports: [HeaderComponent],
})
export class EntryModule { }
