import { AfterViewInit, Component, HostListener, NgZone, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { UseAppBannerComponent } from 'src/app/shared/use-app-banner/use-app-banner.component';
import { Services } from 'src/app/_core/model/service';
import { AuthenticationService } from 'src/app/_core/services/authentication.service';
import { CommonService } from 'src/app/_core/services/common.service';
import { DataService } from 'src/app/_core/services/data.service';
import { DeviceInfoService } from 'src/app/_core/services/device-info.service';
import { FirestoreService } from 'src/app/_core/services/firestore.service';
import { HelperService } from 'src/app/_core/services/helper.service';
import { PlayerService } from 'src/app/_core/services/player.service';
import { ScreensService } from 'src/app/_core/services/screens.service';
import { environment } from 'src/environments/environment';
import {Platform} from '@angular/cdk/platform';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit, AfterViewInit{

  categories: any = [];
  sliders: any = [];
  isPageLoaded = false;
  skeletons = [0, 0, 0, 0];
  continueWatching: any;
  user:any;

  screenId:any;
  selectedScreen:any;
  moreDataLoaded = false;

  constructor(
    private helperService: HelperService,
    private commonService: CommonService,
    private router: Router,
    private fireStoreService: FirestoreService,
    private dataService: DataService,
    private authenticationService: AuthenticationService,
    private screenService: ScreensService,
    private playerService: PlayerService,
    private matDialog: MatDialog,
    private deviceInfoService: DeviceInfoService,
    private platform: Platform
  ) {

  }

  @HostListener('window:scroll', ['$event']) onScroll(event){
    if((document.body.scrollHeight < document.documentElement.scrollTop) && !this.moreDataLoaded) {
      this.dataService.isScrolled.subscribe(res => {
        this.moreDataLoaded = res;
      })
      setTimeout(() => {
        this.getMoreCategory(this.screenId)
      }, 1000);
      this.moreDataLoaded = true;
    }
  }

  ngOnInit() {
    this.user = this.authenticationService.currentUser();
    this.dataService.isStepperAvailable(false);
    if(this.user) {
      switch (this.user.user_account_status) {
        case 'PROFILE':
          let encoded = {
            id: this.user.user_id,
            username: this.user.username,
            isNew: true,
            account: true,
            // profile: true,
            packageId: 1
          }
          let queryData = window.btoa(JSON.stringify(encoded))
          this.dataService.isStepperAvailable(true)
          this.router.navigate(['/account/new-account/creation'], {queryParams: {data: queryData}});
          break;
        case 'PACKAGE':
          let encodedPkg = {
            id: this.user.user_id,
            username: this.user.username,
            isNew: true,
            account: true,
            profile: true,
            // package: true,
            packageId: 1
          }
          let queryDataPkg = window.btoa(JSON.stringify(encodedPkg))
          this.dataService.isStepperAvailable(true)
          this.router.navigate(['/account/package-select'], {queryParams: {data: queryDataPkg}});
          break;
      }
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.subscribeHeaderComponent();
    }, 500);
  }

  subscribeHeaderComponent() {
    this.helperService.initialRefresh.subscribe(
      (navigationData) => {
        if (navigationData) {
          this.screenId = navigationData;
          this.getWatchList();
          this.loadScreen(navigationData);
        }
      }
    )
  }

  loadScreen(currentScreenId) {
    this.commonService.findAll(Services.SCREENS, {
      screenId: currentScreenId,
      size: 9, //default environment.size, changed to 9 because dflix originals tharanya content load twice
      page: environment.page
    }).subscribe((screens: any) => {
      this.categories = [];
      var screen;
      if (!!currentScreenId) {
        screen = screens.data.find((x) => {
          if (x.id == currentScreenId)
            return x;
        });
      } else {
        screen = screens.data[0];
      }
      // this.selectedScreen = screen;
      // this.categories = this.helperService.mapCategory(screen, environment.page);

      this.findContinueWatching(screen);
      this.sliders.forEach((element, index) => {//remove posters that does not have image url
        if (!!!element.poster)
          this.sliders.splice(index, 1);
      });

      if(this.isPageLoaded) {
        if(this.deviceInfoService.getDeviceType() == 'MOBILE' && sessionStorage.getItem('skipped') != 'true') {
          this.openWithApp();
        }
        if(this.deviceInfoService.getDeviceType() == 'TABLET' && sessionStorage.getItem('skipped') != 'true') {
          this.openWithApp();
        }
      }
    });
  }

  getMoreCategory(screenId) {
    this.commonService.findAll(Services.SCREENS, {
      screenId: screenId,
      size: environment.size,
      page: environment.page + 1
    }).subscribe((screens: any) => {
      var screen;
      if (!!screenId) {
        screen = screens.data.find((x) => {
          if (x.id == screenId)
          return x;
        });
      } else {
        screen = screens.data[0];
      }
      this.moreDataLoaded = !screen.has_next_page;
      this.categories = [...this.categories, ...this.helperService.mapCategory(screen, environment.page + 1)];
    });
  }

  findContinueWatching(screen) {
    let watchHistory = screen.categories.find(element =>
      element.category_template == "WATCH_HISTORY"
    );
    if (watchHistory) {
      this.fireStoreService.getWatchHistoryAll([...watchHistory.content_types]).then( //remove video from array
        (res) => {
          this.continueWatching = {
            name: watchHistory.name,
            display_type: "STANDARD",
          }
          this.continueWatching['content'] = res.docs.map((doc) => {
            let posters = (!!doc.data().posterUrls[0].data) ? doc.data().posterUrls[0].data : doc.data().posterUrls
            // var rawTitle = doc.data().contentDetailSlug.split("_").join("  ");
            // var newTitle = rawTitle.toLowerCase().split(' ').map(s => s.charAt(0).toUpperCase() + s.substring(1)).join(' ');
            return {
              fullData: doc.data(),
              url: (!!!doc.data().posterUrls) ? "/assets/images/default/default_landscape.jpg" : this.helperService.getPosterUrlByFiltering(posters, ["ONE_X", "TWO_X", "THREE_X", "FOUR_X"], "LANDSCAPE", true),
              timeWidth: (((doc.data().lastWatchedPosition) / doc.data().contentLength) * 100).toFixed(2) + '%',
              run_time: doc.data().contentLength / 60000,
              genre: doc.data().genre,
              name: doc.data().title ? doc.data().title.english : doc.data().contentDetailSlug, // remove slug after
            }
          });
          this.categories = this.helperService.mapCategory(screen, environment.page, this.continueWatching);
          this.sliders = this.mapSliders(screen);
          this.isPageLoaded = true;

        }
      );
    } else {
      this.continueWatching = [];
      this.categories = this.helperService.mapCategory(screen, environment.page, this.continueWatching);
      this.sliders = this.mapSliders(screen);
      this.isPageLoaded = true;
    }
  }


  selectContent(content) {
    if(content.data.type == "LIVE_CHANNEL") {
      if(this.user) {
        if (content.isContinueWatching) {
          this.playerService.returnUrl = this.router.url;
          let videoDetails: any = {
            autoplay: true,
            tagUrl: ' ',
            last_time: content.data.lastWatchedPosition / 1000,//last time in seconds
            tv_show_slug: content.data.contentSlug,
            tv_show_id: content.data.contentId,
            tv_show_name: content.data.contentDetailSlug
          };
          this.playerService.playVideo(content.data.contentType, content.data.contentDetailSlug, videoDetails, false);
        } else {
          if (content.isPlay || content.data.type=='TV_EPISODE' || content.data.type=='VIDEO') {
            this.playerService.returnUrl = this.router.url;
            let videoDetails: any = {
              autoplay: true,
              tagUrl: ' ',
              last_time: 0,
            };
            this.playerService.playVideo(content.data.type, content.data.slug, videoDetails, false)
          } else {
            let ssr = {
              title: content.data.name,
              image: this.helperService.getPosterUrlByFiltering(content.data.posters, ["ONE_X", "TWO_X", "THREE_X", "FOUR_X"], "LANDSCAPE", true),
              desc: content.data.description
            }
            this.router.navigate(
              ['other',
                this.helperService.changeRouterLink(content.data.type),
                content.data.slug
              ]);
          }
        }
      } else {
        this.router.navigate(['/account/login'])
      }
    } else {
      if (content.isContinueWatching && content.isPlay) {
        this.playerService.returnUrl = this.router.url;
        let videoDetails: any = {
          autoplay: true,
          tagUrl: ' ',
          last_time: content.data.lastWatchedPosition / 1000,//last time in seconds
          tv_show_slug: content.data.contentSlug,
          tv_show_id: content.data.contentId,
          tv_show_name: content.data.contentDetailSlug,
          episodeNumber: content.data.episodeNumber,
          seasonId: content.data.seasonId
        };
        if(content.data.episodeNumber !== undefined) {
          this.playerService.playVideo("tv_episodes", content.data.contentDetailSlug, videoDetails, false);
        } else {
          this.playerService.playVideo(content.data.contentType, content.data.contentDetailSlug, videoDetails, false);
        }
      } else {
        if (content.isPlay || content.data.type=='TV_EPISODE' || content.data.type=='VIDEO') {
          this.playerService.returnUrl = this.router.url;
          let videoDetails: any = {
            autoplay: true,
            tagUrl: ' ',
            last_time: 0,
          };
          this.playerService.playVideo(content.data.type, content.data.slug, videoDetails, false)
        } else {
          if(content.data.lastWatchedPosition) {
            this.router.navigate(
              ['other',
                this.helperService.changeRouterLink(content.data.contentType),
                content.data.contentDetailSlug
              ]);
          } else {
            this.router.navigate(
              ['other',
                this.helperService.changeRouterLink(content.data.type),
                content.data.slug
              ]);
          }
        }
      }
    }
    this.addScollHeaderColor();
  }
  addScollHeaderColor() {
    var element: any = document.getElementById("nav-bar");
    if(element != null) {
      element.classList.remove("nav-bar-color");
      element.classList.add('nav-bar-color-scroll');
    }
  }
  mapSliders(screen) {
    return (screen.sliders.length > 0) ? screen.sliders.map((slider) => {
      return {
        content: slider.content,
        description: slider.content.description.split("").splice(0, 200).join("") + "...",
        cta_button: slider.cta_button,
        is_ctabutton_enabled: slider.is_ctabutton_enabled,
        is_share_button_enabled: slider.is_share_button_enabled,
        is_watch_list_button_enabled: slider.is_watch_list_button_enabled,
        main_text: slider.main_text,
        sub_text: slider.sub_text,
        type: slider.type,
        posters: slider.posters,
        poster: (slider.posters.length > 0) ? [
          slider.posters.filter((poster) => poster.orientation_type === 'LANDSCAPE')[0],
          slider.posters.filter((poster) => poster.orientation_type === 'PORTRAIT')[0]
        ] : [
          { converted_url: "/assets/images/default/default_landscape.jpg" },
          { converted_url: "/assets/images/default/default_portrait.jpg" }
        ],
        slider_graphic_text_image: (slider.slider_graphic_text_images.length > 0) ? slider.slider_graphic_text_images[0].converted_url : null,
        review_video_url: slider.review_video_url
      }
    }) : [{
      content: "",
      cta_button: "",
      is_ctabutton_enabled: "",
      is_share_button_enabled: "",
      is_watch_list_button_enabled: "",
      main_text: "",
      sub_text: "",
      type: "",
      posters: [],
      poster: [
        { converted_url: "/assets/images/default/default_landscape.jpg" },
        { converted_url: "/assets/images/default/default_portrait.jpg" }
      ],
      slider_graphic_text_image: "/assets/images/default/def_title.png"
    }];
  }
  addColor(index) {
    if (index == 0 && window.innerWidth > 600) {
      return 'white';
    }
  }
  getWatchList() {
    if (!!this.authenticationService.currentUser()) {
      this.fireStoreService.getAddToWatchList(false)
        .then(
          (res) => {
            let watchList = res.docs.map(
              (doc) => {
                let data: any = doc.data();
                return data;
              }
            );
            this.dataService.passAddWatchListData(watchList);
          }
        );
    }
  }
  retrieveMoreData(category, index) {
    if (category.has_next_page) {
      this.categories[index].isApiRequested = true;
      this.screenService.getCategoryById(category.id, ++category.currentPage, environment.size)
        .subscribe(
          (res) => {
            let mappedData;
            if(category.display_type == 'SPECIAL' || this.deviceInfoService.getDeviceType() == 'MOBILE'){
              mappedData = this.helperService.mapContent(res.content, true);
            } else {
              mappedData = this.helperService.mapContent(res.content, false);
            }
            this.categories[index].has_next_page = res.has_next_page;
            this.categories[index].content = [...this.categories[index].content, ...mappedData];
            this.categories[index].isApiRequested = false;
            this.categories[index].currentPage = category.currentPage;
          });
    }
  }
  getPosterWidth(category) {
    switch (category.display_type) {
      case "STANDARD":
        return (window.innerWidth > 600) ? 300 : 100;
      case "ADVERTISEMENT":
        return (window.innerWidth > 600) ? 600 : 100;
      case "FEATURED":
        return (window.innerWidth > 600) ? 600 : 100;
      case "VIDEO":
        return (window.innerWidth > 600) ? 450 : 150;
      case "SPECIAL":
        return (window.innerWidth > 600) ? 300 : 100;
      default:
        return (window.innerWidth > 600) ? 300 : 100;
    }
  }

  openWithApp() {
    // if(navigator.userAgent.match(/SAMSUNG|Samsung|SGH-[I|N|T]|GT-[I|N]|SM-[A|N|P|T|Z]|SHV-E|SCH-[I|J|R|S]|SPH-L/i)) {
    //   window.location.href = 'https://play.google.com/store/apps/details?id=lk.rumex.deranareality';
    //   // your code for Samsung Smartphones goes here...
    // }
    /*if(this.platform.ANDROID) {
      window.location.href = 'https://play.google.com/store/apps/details?id=lk.rumex.deranareality';
    }if(this.platform.IOS) {
      window.location.href = 'https://apps.apple.com/us/app/dflix/id1560605917?platform=iphone';
    } */
      this.matDialog.open(UseAppBannerComponent, {disableClose: true});
  }
}
