import { Injectable } from '@angular/core';
import { HttpService } from './http/http.service';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';

@Injectable()
export class PaymentService {

  constructor(
    private http: HttpService
  ) { }

  oneTimePurchase(body: any) {
    body["deviceType"] = "WEBSITE";
    return this.http.post(`${environment.paymentServiceUrl}payments`, body, { headers: { 'is-error-handled': 'true' } });
  }
  sendPaymentCompletionReq(clientRef, source?) {
    if(source) {
      return this.http.get(`${environment.paymentServiceUrl}complete-payment/card?clientRef=${clientRef}&source=${source}&deviceType=WEBSITE`, { headers: { 'is-error-handled': 'true' } });
    } else {
      return this.http.get(`${environment.paymentServiceUrl}complete-payment/card?clientRef=${clientRef}&deviceType=WEBSITE`, { headers: { 'is-error-handled': 'true' } });
    }
  }
  subscribeToDialog(body) {
    return this.http.post(`${environment.paymentServiceUrl}subscriptions/subscribe`, body);
  }
  verifyDialogOTPpin(body) {
    return this.http.post(`${environment.paymentServiceUrl}subscriptions/submit-pin`, body);
  }
  getBillingHistory(userId: number, page: number, size: number): Observable<any> {
    return this.http.get(`${environment.paymentServiceUrl}payments/${userId}?page=${page}&size=${size}`);
  }
  subscribePayment(body: any): Observable<any> {
    body["deviceType"] = "WEBSITE";
    return this.http.post(`${environment.paymentServiceUrl}payments/subscribe`, body);
  }
  sendOneTimeMobilePayment(body: any): Observable<any> {
    return this.http.post(`${environment.paymentServiceUrl}complete-payment/mobile`, body);
  }
  completeDialogSubscription(paymentRef: string): Observable<any> {
    return this.http.get(`${environment.paymentServiceUrl}payments/complete-subscribe/mobile?payRef=${paymentRef}`);
  }
  requestCardChange(body: any): Observable<any> {
    return this.http.post(`${environment.paymentServiceUrl}payments/change-card`, body)
  }
  completeCardChangeRequest(clientRef: string, source?: string): Observable<any> {
    if(source) {
      return this.http.get(`${environment.paymentServiceUrl}payments/complete/change-card?clientRef=${clientRef}&source=${source}&deviceType=WEBSITE`, { headers: { 'is-error-handled': 'true' } });
    } else {
      return this.http.get(`${environment.paymentServiceUrl}payments/complete/change-card?clientRef=${clientRef}&deviceType=WEBSITE`, { headers: { 'is-error-handled': 'true' } });
    }
  }
  requestNumberChange(body: any) {
    return this.http.post(`${environment.paymentServiceUrl}payments/change-mobile`, body)
  }
  retryPayment(userId: number): Observable<any> {
    return this.http.get(`${environment.paymentServiceUrl}payments/manual-subscribe?userId=${userId}`);
  }
  getPaymentMethods(userId: number): Observable<any> {
    return this.http.get(`${environment.paymentServiceUrl}payments/details?userId=${userId}`);
  }
  switchPreferenceToCard(cardId, preferred): Observable<any> {
    return this.http.get(`${environment.paymentServiceUrl}payments/switch-card?card-id=${cardId}&isPreffered=${preferred}`)
  }
  getPackageDetails(): Observable<any> {
    return this.http.get(`${environment.contentUrl}packages/`, { headers: { 'is-error-handled': 'true' } })
  }
  //new paayment api
  ipgRentalPayment(body: any): Observable<any> {
    return this.http.post(`${environment.paymentServiceUrl}payments/rental/selcted-card`, body);
  }

  //new payment api for subscription
  ipgSubscriptionPayment(body: any): Observable<any> {
    return this.http.post(`${environment.paymentServiceUrl}payments/subscription/selcted-card`, body);
  }

  removePaymentCardMethod(id): Observable<any> {
    return this.http.get(`${environment.paymentServiceUrl}payments/remove-card?card-id=${id}`);
  }

  removePaymentMobileMethod(id): Observable<any> {
    return this.http.get(`${environment.paymentServiceUrl}payments/remove-mobile?id=${id}`);
  }

}
